import { UserManagerService } from './../services/userManager/user-manager.service';
import { HttpService } from './../../wrappers/Http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  private _mobileActiveAccountstab: number = 1 ;
  getOTP(withdrawalAmt: string): Promise<any> {
    var data = {
      sessionId: this.userService.userData.session,
      amount: withdrawalAmt
    }
    return new Promise((resolve, reject) => {
      this.requestHandler.authPost(this.configService.PROXIES.userService , this.configService.EndPoints.REQUEST_OTP, data).then(response => {
        if (response.hasOwnProperty("erc") && response.erc == '1') {
          resolve(response)
        } else {
          reject(response)
        }
      })
    })
  }

  public paymentProviders: any = {};
  private _activeAccountstab = 1;
  private _usersbanks: Array<any>;
  public get usersbanks(): Array<any> {
    return this._usersbanks;
  }
  public set usersbanks(value: Array<any>) {
    this._usersbanks = value;
  }
  private _tabs: Array<any> = [];
  public get tabs(): Array<any> {
    return this._tabs;
  }
  public set tabs(value: Array<any>) {
    this._tabs = value;
  }

  public get activeAccountstab(): number {
    return this._activeAccountstab;
  }
  public set activeAccountstab(value: number) {
    this._activeAccountstab = value;
  }
  public get mobileActiveAccountstab(): number {
    return this._mobileActiveAccountstab;
  }
  public set mobileActiveAccountstab(value: number) {
    this._mobileActiveAccountstab = value;
  }
  constructor(private requestHandler: HttpService, private userService: UserManagerService, private configService: ConfigService) { }

  getProviders(id: any): Promise<any> {
    const api = id === 1 ? 'cashInProviders' : 'cashOutProviders';
    if(this.paymentProviders[id])
    return Promise.resolve(this.paymentProviders[id]);
    return this.requestHandler.get_request(this.configService.PROXIES.userService , this.configService.EndPoints.providers + api, {}).then(response => {
      const providers = [];
      response.data.forEach(element => {
        if (element.hasOwnProperty('sub_providers') && element.sub_providers.length > 0) {
          element.sub_providers.forEach(subProv => {
           
            providers.push(this.buildProvObj(subProv, element));
          });
        } else {
          // element.pdv = element.code;
          providers.push(this.buildProvObj(element, element));
        }
      });
      this.paymentProviders[id] = providers;
      if (id == 2){
        let context = this;
      if(context.userService.userData&&context.userService.userData.providers && context.userService.userData.providers.length > 0){
        context.userService.userData.providers = this.setProvPhoneNumbers(context.userService.userData.providers);
        this.paymentProviders[2]  = this.paymentProviders[2].filter(function(array_el){
          // console.log('filtering')
          return context.userService.userData.providers.filter(function(anotherOne_el){
             return (anotherOne_el.pvd == array_el.code || !array_el.is_mobile);
          }).length >= 1
       });
       
      }
      else{
        this.paymentProviders[2]  = this.paymentProviders[2].filter(function(array_el){
          // console.log('filtering')
             return (!array_el.is_mobile);
       });
      }
      //  console.log(this.paymentProviders[2], "filtered object")
      }
      // console.log(providers)
    });
  }

  buildProvObj(subProv, element){
    subProv.pdv = element.code;
    return subProv;
  }
  setProvPhoneNumbers(providers:Array<any>){
    providers.forEach(subProv=>{
      if(subProv.accnt && subProv.accnt.startsWith(this.configService.country_code)){
        subProv.accnt = subProv.accnt.split(this.configService.country_code)[1]
      }
     })

     return providers;
   
  }
  triggerWithdrawal(arg0: any, accountParams: any) {
    return this.requestHandler.authPost(this.configService.PROXIES.userService , this.configService.EndPoints[arg0], accountParams, );
  }
  triggerDeposit(arg0: any, accountParams: any) {
    return this.requestHandler.authPost(this.configService.PROXIES.userService , this.configService.EndPoints[arg0], accountParams);
  }
  getUserBanks() {
    // this.requestHandler.get_request(this.configService.PROXIES.userService + 'account/' + this.userService.userData.customer_ID, {}).then(responseData => {
    //   this.usersbanks = responseData;
    // });
  }
  validateOTP(accountParams: any) {
    return this.requestHandler.authPost(this.configService.PROXIES.userService , this.configService.EndPoints[accountParams['pdvurl'] + '_CONFIRMATION'], accountParams);
  }
}
