import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigService } from './config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigResolverService implements Resolve<any> {
  constructor(private _http: HttpClient, private config: ConfigService) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return  this.config.loadConfigs()
  }
}