import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigResolverService } from './providers/config-resolver.service';

// const routes: Routes = [
//   { path: '', loadChildren: './pages/landing-page/landing-page.module#LandingPageModule' },
//   { path: 'home', redirectTo: '' },
//   { path: 'registration', loadChildren: './pages/registration/registration.module#RegistrationModule' },
//   { path: 'rules-page', loadChildren: './pages/footer-page/footer-page.module#FooterPageModule' },
// ];

const routes: Routes = [
  { path: '', loadChildren: './pages/pages.module#PagesModule', resolve: {
    config: ConfigResolverService} }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class SportifWebRoutingModule { }
