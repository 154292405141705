export interface Theme {
  name: string;
  properties: any;
  common?: string;
}

export const tunisia: Theme = {
  name: 'tunisia',
  properties: {
    '--app-color-base': '#fff',
    '--app-color-heading-5': '',
    '--app-color-white': '#fff',

    '--app-color-primary': '#fff',
    '--app-color-primary-tint': '#d7d7d7',
    '--app-color-primary-tint-0': '',
    '--app-color-primary-tint-2': '',
    '--app-color-primary-tint-3': '',
    '--app-color-primary-tint-5': '',
    '--app-color-secondary': '#000',
    '--app-color-tertiary': '#fad63f',
    '--app-color-danger': '#F83D3C',
    '--app-color-header-about': '',

    "--app-color-promotions": "#fff",
    "--app-color-lexique": "#fff",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",
    "--app-color-checkbox-input-border": "#fff",

    '--app-background-gray': '#9d9d9c ',
    '--app-background-base': '#004780',
    '--app-background-primary': '#013d6d',
    '--app-background-primary-tint': '#01569b',
    '--app-background-secondary': '#004d8c',
    '--app-background-secondary-tint': '#e85696',
    '--app-background-tertiary': '#023055',
    '--app-background-tertiary-tint': '#004780',
    '--app-background-tertiary-tint-1': '',

    '--app-background-promotions': '#009d48',
    "--app-background-border-promotions": "#009d48",
    "--app-background-cmtJr": "#5d5d5d",
    "--app-background-lexique": "#5d5d5d",
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-background-checkbox-input": "#eb862c",
  }
};

export const lonaci: Theme = {
  name: 'lonaci',
  common: 'SPORTCASH',
  properties: {
    '--app-color-base': '#17633b',
    '--app-color-heading-5': '#cbf9e0',
    '--app-color-white': '#fff',

    '--app-color-primary': '#fff',
    '--app-color-primary-tint': '#dedede',
    '--app-color-primary-tint-0': '#e6e6e6',
    '--app-color-primary-tint-2': '#bbbbbb',
    '--app-color-primary-tint-3': '#c3c3c3',
    '--app-color-primary-tint-5': '#d4d4d4',
    '--app-color-secondary': '#000',
    '--app-color-danger': '#F83D3C',
    '--app-color-tertiary': '#64c388',
    '--app-color-tertiary-tint': '#eb862c',
    '--app-color-header-about': '#00ff75',
    "--app-color-promotions": "#fff",
    "--app-color-lexique": "#fff",
    "--app-color-checkbox-input-border-unchecked": "#777676e5",
    "--app-color-checkbox-input-border": "#fff",

    // background colors
    '--app-background-gray': '#037b3a',
    '--app-background-gray-tint': '#5d5d5d ',
    '--app-background-base': '#fff',
    '--app-background-primary': '#176334',
    '--app-background-primary-tint': '#009d48',
    '--app-background-four-tint': '#037b3a',
    '--app-background-four-tint-1': '#00ff61',
    '--app-background-tertiary': '#c38411',
    '--app-background-tertiary-tint': '#154e2b',
    '--app-background-tertiary-tint-1': '#ff9900',
    '--app-background-tertiary-dark': '#2f2f2f',
    // '--app-background-tertiary-tint': '#2f2f2f',
    '--app-background-secondary-tint': '#eb862c',
    '--app-background-primary-tint-contrast': '#ccbfbf',

    '--app-background-promotions': '#009d48',
    "--app-background-border-promotions": "#009d48",
    "--app-background-cmtJr": "#5d5d5d",
    "--app-background-lexique": "#5d5d5d",
    "--app-background-checkbox-input-unchecked": "#ffffffa4",
    "--app-background-checkbox-input": "#eb862c",

  }
};

