import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { StorageService } from 'src/app/providers/storage.service';

@Component({
  selector: 'app-acc-paiement',
  templateUrl: './acc-paiement.component.html',
  styleUrls: ['./acc-paiement.component.scss']
})
export class AccPaiementComponent implements OnInit {
  addAccount: boolean;
  accnt: string;
  pvd: any;
  otpRequired: boolean;
  msg: any;
  procesingOTP: boolean;
  otp: any;
  showAddMethodForm: boolean;
  activeMethod: any;
  invalidMessge: string;
  invalidNumber: boolean;

  constructor(public usermanagerService: UserManagerService,
    private storageService: StorageService,
    public providerService: MyAccountService,
    private translateService: TranslateService,
    public configService: ConfigService,
    private modalService: ModalService) { }

  ngOnInit() {
    this.providerService.getProviders(0).then(res => {
      // console.log(this.providerService.paymentProviders[0])
    })
  }
  addAcc(){
    this.addAccount = !this.addAccount
  }
  initMsgs(){
    this.msg = undefined;
  }
  sendNumberOTP() {
    this.initMsgs();
    this.verifyPhoneNumber();
    if(this.invalidNumber){
      this.msg = "invalid_phonenumber";
      return;
    }
    this.usermanagerService.isProcessing = true;
    const request = {
      sid: this.usermanagerService.userData.session,
      pvd: this.pvd.split('-')[1],
      accnt:  this.accnt
      // accnt: this.configService.country_code + this.accnt
    }
    this.usermanagerService.saveAccount(request).then(data => {
      // console.log(data)
      this.otpRequired = true;
    }).catch(err => {
      this.msg = err.msg ? err.msg : err.message;
    //  console.log(this.msg)
    })
      .finally(() => {
        this.usermanagerService.isProcessing = false;
      })
    // console.log("adding new number")
  }
  validateOTP() {
    this.initMsgs();
    this.procesingOTP = true;
    const requestData = {
      sid: this.usermanagerService.userData.session,
      otp: this.otp,
      id: this.pvd.split('-')[1],
      pvd: this.pvd.split('-')[0]
    };
    this.usermanagerService.confirmpaymentAccount(requestData).then(response => {
      this.msg = this.translateService.instant("operation_successful");
      this.otpRequired = false;
      this.usermanagerService.userData.providers = this.providerService.setProvPhoneNumbers(response.data);
      this.showAddMethodForm = false;
      this.providerService.getProviders(2);
      this.closeModals();
    }).catch(err => {
      this.msg = err.msg ? err.msg : err.message;
    //  console.log(this.msg)
    })
    .finally(() => {
      this.procesingOTP = false;
    })
  }
  closeModals(){
    this.otp = '';
    this.pvd = ''
    this.addAccount = false;
    this.otpRequired = false;
    this.accnt = ''
  }
  verifyPhoneNumber(){
    let mask = this.pvd.split('-')[2];
    console.log(mask);

    console.log(this.pvd);
    let length1 = mask && mask.indexOf('#') > -1  ? mask.split('#')[1]: this.configService.phoneNumberLength;
    var pattern = /^[0-9]+$/
    // // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/
    var checkval = pattern.test(this.accnt);
    if(!checkval){
      this.invalidMessge ="invalid_character_in_phone_number";
    }
    let phoneLength = this.accnt.toString().length
    if( length1 && (Number(phoneLength) !== Number(length1))){
      checkval =false;
      this.invalidMessge ="invalid_length";
    }
    if(!checkval) {
      this.invalidNumber = true;
     
    } else {
      let testMasks = mask && mask.indexOf('#')? mask.split('#')[0].split(','): [];
      if(testMasks && testMasks.length>0)
     { 
       for(let maskitem of testMasks){
        if (this.accnt.toString().indexOf(maskitem) == 0)
        { this.invalidMessge = "";
          return this.invalidNumber = false;}
        else{
          this.invalidNumber =true
          this.invalidMessge ="invalid_prov_code";
        }
        
      }
      } else {
        this.invalidNumber =false
        this.invalidMessge = "";
      }
    }
  }
}
