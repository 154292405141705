import { ModalService, DialogInterface } from './providers/modal-service/ModalService';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { SportifWebRoutingModule } from './app-routing.module';
import { SkeletonServiceService } from './components/skeleton-service.service';
import { DomService } from './providers/modal-service/DOMService';
import { UserManagerService } from './providers/services/userManager/user-manager.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpService } from './wrappers/Http';
import { TimeHandlerService } from './providers/time-handler.service';
import { SkeletonComponentsModule } from './components/skeleton-components/skeleton-components.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StorageService } from './providers/storage.service';
import { WebsocketService } from './providers/WebsocketService';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { ConfigService } from './providers/config/config.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {MatDialogModule} from '@angular/material/dialog';
import { AuthService } from './providers/auth.service';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    DialogInterface 
  ],
  entryComponents:[DialogInterface],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SkeletonComponentsModule,
    HttpClientModule,
    MatDialogModule,
    SportifWebRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [StorageService, TranslateService, SkeletonServiceService,    AuthService,
    ModalService, UserManagerService, DomService, TimeHandlerService, HttpService,
   WebsocketService,
    ConfigService],
  bootstrap: [AppComponent]
})
export class AppModule {

}
