import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogInterface, DialogData } from 'src/app/providers/modal-service/ModalService';
import { ConfigService } from 'src/app/providers/config/config.service';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {
  imagesRepo: any;
  data
  constructor(public dialogRef: MatDialogRef<DialogInterface>,
    @Inject(MAT_DIALOG_DATA) public inputData: DialogData, public configs: ConfigService) {
    this.imagesRepo = this.configs.PROXIES.IMAGES_REPO;
    this.data = inputData;
  }

  ngOnInit() {
  }

}
