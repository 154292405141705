import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';

@Component({
  selector: 'app-acc-recharger',
  templateUrl: './acc-recharger.component.html',
  styleUrls: ['./acc-recharger.component.scss']
})
export class AccRechargerComponent implements OnInit {
  providers: any = {};
  paymentMethods: Array<any> = [];
  filteredAccnts: Array<any> = [];
  activeMethod: any;
  accountParams: any = {};
  isLoading: boolean;
  procesingOTP: boolean;
  msg: string;
  phoneNumber: any = "";
  isProcessingDeposit: boolean = false;
  msgDeposit: any;
  requireConfirmation: boolean;
  depositAmt: any;
  depositCode: any;
  suffixPhone: any = "";
  otp: any;
  invalidMessge: string;
  invalidNumber: boolean;
  constructor(public usermanagerService: UserManagerService,
    public accountService: MyAccountService,
    public configService: ConfigService,
    public translateService: TranslateService,
    public modalService: ModalService, ) {
    if (this.usermanagerService.userData)
      this.accountParams = {
        uid: this.usermanagerService.userData.customer_ID,
        lgn: this.usermanagerService.userData.login,
        pin: this.usermanagerService.userData.str_PIN ? this.usermanagerService.userData.str_PIN : '',
        chl: '3',
        wtp: 'B',
        ref: '' + new Date().getTime(),
        id: this.usermanagerService.userData.external_payment_tef,
        acc: this.usermanagerService.userData.account_code,
        lang: this.usermanagerService.userData.lang ? this.usermanagerService.userData.lang : navigator.language.split('-')[0],
        opr: ''
      };
  }

  ngOnInit() {
    this.accountService.getProviders(2).then(res => {
      // console.log(this.accountService.paymentProviders[1])
    })
  }
  selectMethod(i) {
    this.activeMethod = this.accountService.paymentProviders[1][i];
    // console.log('change active method', this.activeMethod)
    this.msg = undefined;
    if (this.usermanagerService.userData && this.usermanagerService.userData.providers)
      this.filteredAccnts = this.usermanagerService.userData.providers.filter(account => {
        return account.pvd == this.activeMethod.code;
      });

    this.phoneNumber = this.filteredAccnts && this.filteredAccnts.length > 0 ? this.filteredAccnts[0].accnt : undefined;
    if (this.phoneNumber && this.phoneNumber.length) {
      this.verifyPhoneNumber();

    }
    // console.log(this.phoneNumber)
  }
  closeModal() {
    this.requireConfirmation = false;
  }
  triggeDeposit() {
    this.initMsgs();
    if (!this.activeMethod.is_voucher) {
      if (isNaN(Number(this.depositAmt)) || !this.depositAmt) {
        this.msg = "Montant invalide ";
        return;
      }
      if (this.activeMethod.min && (Number(this.depositAmt) < Number(this.activeMethod.min))) {
        this.msg = "Le montant minimum autorisé pour cette méthode est ";
        return;
      }
      if (this.activeMethod.max && (Number(this.depositAmt) > Number(this.activeMethod.max))) {
        this.msg = "Le montant maximum autorisé pour cette méthode est ";
        return;
      }
      if (this.activeMethod.is_mobile && (isNaN(Number(this.phoneNumber)) || this.phoneNumber.trim() == "")) {
        this.msg = "Numero de telephone invalide ";
        return;
      }
    }

    this.accountParams.amt = this.depositAmt + "";
    this.accountParams.code = this.depositCode;
    this.accountParams.phn = this.phoneNumber + this.suffixPhone;
    this.accountParams.pdi = this.activeMethod.id;
    this.accountParams.opr = this.activeMethod.id + "";
    this.accountParams.pvd = this.activeMethod.pdv;
    this.isProcessingDeposit = true;
    this.accountParams['pdvurl'] = this.activeMethod.code == 'LONACI' ? "LONACI_DEPOSIT" : this.activeMethod['pdv'].replace(' ', '') + '_DEPOSIT';
    this.accountService.triggerDeposit(this.accountParams.pdvurl, this.accountParams).then(data => {
      if (data && data.length > 0) {
        if (data[0].erc == '1') {
          if (!this.activeMethod.is_one_step) {
            this.requireConfirmation = true;
            this.accountParams.transid = data[0].ERROR_MESSAGE.split('-')[1];
          } else {
            this.modalService.destroy();
            this.modalService.msg = this.activeMethod.is_mobile ? data[0].ERROR_MESSAGE.split('-')[0] : 'deposited_successfully';
            this.modalService.title = 'Success';
            this.modalService.openDialog(this.modalService.title, this.modalService.msg, false).then(res => {
              this.usermanagerService.moveBack();
            });
            this.depositAmt = '';
            this.msg = this.activeMethod.is_mobile ? data[0].ERROR_MESSAGE.toString().split('-')[0] : 'deposited_successfully';
            if (data[0].balances) {
              this.usermanagerService.updateUserAttribute('balances', data[0].balances);
            }
          }

        } else {
          this.msg = this.msgDeposit = data[0].ERROR_MESSAGE && data[0].ERROR_MESSAGE.length > 0 ? data[0].ERROR_MESSAGE : 'transaction_failed';
        }
      }
      this.isProcessingDeposit = false;
    }).catch(error => {
      this.msg = 'verify_internet';
    }).finally(() => {
      this.isProcessingDeposit = false;
      this.phoneNumber = '';
      this.suffixPhone = '';
    });
  }
  initMsgs() {
    this.msg = undefined;
  }
  validateTransaction() {
    // tslint:disable-next-line: triple-equals
    if (!this.otp || this.otp.trim() == '') {
      return;
    }


    this.procesingOTP = true;
    this.accountParams.sms = this.otp;
    // this.accountParams.pdvurl = this.activeMethod.pdv.replace(' ', '') + '_DEPOSIT';
    this.accountService.validateOTP(this.accountParams).then(response => {
      this.procesingOTP = false;
      this.otp = '';
      const data = response[0];
      // const data: any = {ERROR_MESSAGE:"Operation Successful !",erc:"1"}



      // console.log(data);

      // tslint:disable-next-line: triple-equals
      if (data.erc == '1') {
        this.modalService.destroy();
        // this.modalService.msg = 'deposited_successfully';
        this.modalService.msg = data.ERROR_MESSAGE ? this.configService.getErrorMessage(data.ERROR_MESSAGE) : 'deposited_successfully';
        this.modalService.title = this.translateService.instant('ml_success');
        this.modalService.showModal();
        this.msg = undefined;
        this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE);
        if (data.balances) {

          this.usermanagerService.updateUserAttribute('balances', data.balances);
        }
      } else {
        this.requireConfirmation = false;
        this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE);
      }
    }).finally(() => {
      this.procesingOTP = false;
      this.otp = '';
    });
  }
  verifyPhoneNumber() {
    // console.log(this.activeMethod.mask);
    let mask = this.activeMethod.mask
    let length1 = mask && mask.indexOf('#') > -1 ? mask.split('#')[1] : this.configService.phoneNumberLength;
    var pattern = /^[0-9]+$/
    // // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/
    var checkval = pattern.test(this.phoneNumber);
    if (!checkval) {
      this.invalidMessge = "invalid_character_in_phone_number";
    }
    let phoneLength = this.phoneNumber.toString().length
    if (length1 && (Number(phoneLength) !== Number(length1))) {
      checkval = false;
      this.invalidMessge = "invalid_length";
    }
    if (!checkval) {
      this.invalidNumber = true;

    } else {
      let testMasks = mask && mask.indexOf('#') ? mask.split('#')[0].split('-') : [];
      if (testMasks && testMasks.length > 0) {
        for (let maskitem of testMasks) {
          if (this.phoneNumber.toString().indexOf(maskitem) == 0) {
            this.invalidMessge = "";
            return this.invalidNumber = false;
          }
          else {
            this.invalidNumber = true
            this.invalidMessge = "invalid_prov_code";
          }

        }
      } else {
        this.invalidMessge = "";
        this.invalidNumber = false
      }
    }
  }
}
