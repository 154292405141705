import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { ConfigService } from './../../../providers/config/config.service';
import { UserManagerService } from '../../../providers/services/userManager/user-manager.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { MdePopoverTrigger } from '@material-extended/mde';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-my-transactions',
  templateUrl: './my-transactions.component.html',
  styleUrls: ['./my-transactions.component.scss']
})
export class MyTransactionsComponent implements OnInit {
  activeHeader: any;
  status: any = '';
  fromDate: any = new Date();
  toDate: any = new Date();
  transactions: any = [];
  dateChangedEvent = false;
  imagesRepo: any;
  cashoutvalue: any;
  isLoading: boolean;
  isProcessing: boolean;
  activeTransaction = '';
  errorMsg: any;
  params: any = {
    uid: '',
    // status: this.status,
    startDate: new Date(this.fromDate).setHours(0, 0, 0, 0),
    endDate: new Date(this.toDate).setHours(23, 59, 0, 0),
    pvd: '',
    pdt: '',
    page: 0,
    size: 20
  };
  products: any[];
  partners: any[];
  providerId = '';
  productId = '';
  transactionDetails: any;
  type: any;
  sportcash: boolean;
  currency = this.configs.currency;
  typeArray = [{id : 1, name: 'BET'}, { id: 2, name: 'WINNING'}];
  Loading: boolean;
  constructor(public userManagerService: UserManagerService,
              private translateService: TranslateService,
              public modalService: ModalService, public configs: ConfigService) {
    this.activeHeader = this.headers[0];
    this.imagesRepo = this.configs.PROXIES.IMAGES_REPO;
  }
  headers: Array<any> = [{
    name: 'Tous les paris',
    id: 1
  }, {
    name: 'En cours',
    id: 2
  }, {
    name: 'Cash Out effectué',
    id: 3
  }, {
    name: 'Gagné(s)',
    id: 4
  }, {
    name: 'Perdu(s)',
    id: 5
  }, {
    name: ' Annulé(s)',
    id: 6
  }
  ];
  ngOnInit() {
    this.fromDate.setHours(0, 0, 0, 0);

    const context = this;
    this.getHistory();
    this.getPartners();
    this.getProducts();
    $('#dateFrom').datepicker({
      dateFormat: 'mm/dd/yy',
      onSelect: (newDate) => {
        this.dateChangedEvent = true;
        let dateArr = newDate.split("/");
        var fromdt = new Date();
        fromdt.setMonth(Number(dateArr[0]) - 1);
        fromdt.setFullYear(dateArr[2]);
        fromdt.setDate(dateArr[1]);
        fromdt.setHours(0, 0, 0, 0);
        context.fromDate = fromdt.setHours(0, 0, 0, 0);
      }
    });
    $('#dateTo').datepicker({
      dateFormat: 'mm/dd/yy',
      onSelect: (newDate) => {
        this.dateChangedEvent = true;
        let dateArr = newDate.split("/");
        var fromdt = new Date();
        fromdt.setMonth(Number(dateArr[0]) - 1);
        fromdt.setFullYear(dateArr[2]);
        fromdt.setDate(dateArr[1]);
        newDate = fromdt.setHours(23, 59, 0, 0);
        context.toDate = newDate;
      }
    });
  }
getProducts( more = ''){
  // console.log('hey')
  const param = {size: 50, page: 0, pid: this.providerId};
  this.userManagerService.filterProducts(param).then(response => {
    if (more === 'load-more') {
      this.products  = [...this.products, ...response.data];
      // this.productCounts = response.count;
    } else {
      this.products = response.data;
      // this.productCounts = response.count;
    }

});
}
getPartners(more = ''){
  const param = {size: 50, page: 0};
  this.userManagerService.filterPartners(param).then(response => {
    if (more === 'load-more') {
      this.partners  = [...this.partners, ...response.data];
      // this.eventCounts = response.count;
    } else {
      this.partners = response.data;
      // this.eventCounts = response.count;
    }

});
}
open(item){
 var myDiv = document.getElementById('details_' + item.refcode);
//  console.log(item)
 if (myDiv.style.display == "none") {
  $('div[id^="details_"]').css("display", "none");
  myDiv.style.display = "";
} else {
  myDiv.style.display = "none";

}
}
  getHistory(more = '') {
  this.params.uid =  this.userManagerService.userData.customer_ID;
  this.params.startDate = new Date(this.fromDate).setHours(0, 0, 0, 0),
        this.params.endDate = new Date(this.toDate).setHours(23, 59, 0, 0),
        this.params.pvd = this.providerId;
  this.params.pdt = this.productId;
  this.params.type = this.type;
  this.params.sid = this.userManagerService.userData.session;
  this.isLoading = true;
  this.params.sid = this.userManagerService.userData.session
  this.userManagerService.getTransactions(this.params).then(data => {
      if (more === 'load-more') {
        // console.log('more loadded')
        this.transactions  = [...this.transactions, ...data.data];
        // this.productCounts = response.count;
      } else {
        this.transactions = data.data;
        // this.productCounts = response.count;
      }
      
      this.isLoading = false;
    }).catch(err => {
      this.transactions = [];
      this.isLoading = false;
    }).finally(() => {

    });
  }

  loadMoreEvents(){
    // console.log('PERPAGE',this.params.page)
    this.params.page = this.params.page ? this.params.page + 1 : 1;
    this.getHistory('load-more');
  }
  triggerCashOut(transaction: any, trigger?: MdePopoverTrigger) {
    this.activeTransaction = transaction.id;
    this.isProcessing = true;
    this.cashoutvalue = undefined;
    $(trigger).dropdown('dispose');
    this.userManagerService.triggerCashout(encodeURIComponent(transaction.refCode)).then(data => {
      if (data.erc == 1) {
        this.cashoutvalue = data.amount;
      } else {
        this.cashoutvalue = undefined;
        this.errorMsg = data.msg;
      }

      this.isProcessing = false;
      $(trigger).dropdown('toggle');
    }).finally(() => {
      this.isProcessing = false;
      this.activeTransaction = null;
    });
  }
  completeCashout(transaction) {
    this.activeTransaction = transaction.id;
    this.isProcessing = true;
    this.userManagerService.completeCashout(transaction.id, this.cashoutvalue).then(data => {
      if (data && ((data.length > 0 && data[0] && data[0]['14'] === true || data[0]['14'] === 'true') || data.erc == 1)) {
        this.modalService.msg = data.msg ? data.msg : data[0].msg;
        this.modalService.title = this.translateService.instant('ml_success');
        this.modalService.isSuccess = true;
      } else {
        this.modalService.isSuccess = false;
        this.modalService.msg = data.msg ? data.msg : data[0].msg;
        this.modalService.title = this.translateService.instant('ml_error');
      }

      this.modalService.showModal();
    }).finally(() => {
      this.isProcessing = false;
      this.activeTransaction = null;
      this.modalService.destroy();
    });
  }
  getTransactionDetails(item) {
    for (let item of this.transactions){
      item.isdetailsHidden = false;
    }
    
    this.sportcash = item.pvd === 'SPORTCASH' ? true : false;
    if (document.getElementById('details_' + item.refcode).style.display == 'none'){
      this.open(item);
      item.isdetailsHidden = true;
      this.params = {
        uid: this.userManagerService.userData.customer_ID,
        ref: item.refcode
      };
      this.transactionDetails = [];
      this.Loading = true;
      this.userManagerService.getTransactionDetails(this.params, item.pvd).then(data => {
        this.transactionDetails = this.sportcash ? data :  data.data;
        this.Loading = false;
        
      }).catch(err => {
        this.transactionDetails = [];
        this.Loading = false;
      }).finally(() => {
  
      });
    } else {
      this.open(item);
    }
   
  }
}
