import { Injectable, Component, Inject } from '@angular/core';
import { DomService } from './DOMService';
import { BehaviorSubject } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SkeletonServiceService } from 'src/app/components/skeleton-service.service';
declare var $: any;
@Injectable()
export class ModalService {
  event: BehaviorSubject<boolean>;
  constructor(private domService: DomService, public dialog: MatDialog, private skeletonSrvc: SkeletonServiceService) { }

  private modalElementId = 'modal-container';
  private overlayElementId = 'overlay';
  private modalBody = 'modal-body';
  public isSuccess = false;
  public msg: any;
  public isError = false;
  public title: any;
  public subtitle: any;
  public isOpen = false;
  init(component: any, inputs: any, outputs: object) {
    const componentConfig = {
      inputs: inputs,
      outputs: outputs,
    };
    this.domService.removeComponent();
    this.domService.appendComponentTo(
      this.modalBody,
      component,
      componentConfig
    );
    document.getElementById(this.modalElementId).className = "show";
    document.getElementById(this.overlayElementId).className = "show";
  }
  destroy() {
    this.domService.removeComponent();
    document.getElementById(this.modalElementId).className = 'hidden';
    document.getElementById(this.overlayElementId).className = 'hidden';
    this.msg = '';
  }
  showModal() {
    $('#successDialogue').modal('show');
    if(this.skeletonSrvc.theme.name == "lonaci")
      $('#missCIAuthModal').show('show');
  }
  openDialog(header, msg, isConfirm, extraParam?: string): Promise<any> {
    const dialogRef = this.dialog.open(DialogInterface, {
      width: "400px",

      data: {
        yesClicked: false,
        header: header,
        msg: msg,
        isConfirmDialog: isConfirm,
        extraParam: extraParam || null,
      },


    });
    return dialogRef.afterClosed().toPromise();
  }
  openDialogcmp(component: any, data): Promise<any> {
    const dialogRef = this.dialog.open(component, {
      width: '80%',
      height: '70%',
      panelClass: 'no-padding',
      data: { data },
    });
    return dialogRef.afterClosed().toPromise();
  }
}

export interface DialogData {
  yesClicked: boolean;
  isConfirmDialog: boolean;
  header: string;
  msg: string;
  extraParam?: string;
}
@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: 'dialog.html',
})
export class DialogInterface {
  constructor(
    public modalService: ModalService,
    public dialogRef: MatDialogRef<DialogInterface>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.data.yesClicked = false;
    this.dialogRef.close(this.data);

  }
  onYesClick(): void {
    this.data.yesClicked = true;
    this.dialogRef.close(this.data);
  }
}
