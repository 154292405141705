import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';

@Component({
  selector: 'app-my-bonuses',
  templateUrl: './my-bonuses.component.html',
  styleUrls: ['./my-bonuses.component.scss']
})
export class MyBonusesComponent implements OnInit {
  activeHeader: any;
  headers: Array<any> = [
    {
      name: 'sport_book',
      id: 1
    },
    {
      name: 'bonus_history',
      id: 2
    },
   
  ];
  sportBooks = [{
    name: 'sportbet',
    date: new Date(),
    amount: '500',
    remaining_balance: "200",
    expiry_date: new Date()
  },
  {
    name: 'sportbet',
    date: new Date(),
    amount: '500',
    remaining_balance: "200",
    expiry_date: new Date()
  },
  {
    name: 'sportbet',
    date: new Date(),
    amount: '500',
    remaining_balance: "200",
    expiry_date: new Date()
  }]
  constructor(public usermanagerService:  UserManagerService, public config: ConfigService) { }

  ngOnInit() {
    this.switcHeader(this.headers[0]);
  }
  switcHeader(header) {

  // const context = this;
  this.activeHeader = header;
 

}
}
