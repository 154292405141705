import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { SkeletonServiceService } from '../skeleton-service.service';
declare var $: any;
@Component({
  selector: 'app-reset-passowrd',
  templateUrl: './reset-passowrd.component.html',
  styleUrls: ['./reset-passowrd.component.scss']
})
export class ResetPassowrdComponent implements OnInit {
  email: any;
  phone: any;
  login: any;
  option: any = "EMAIL"
  user: any;
  verificationData: any = {};
  showPassword2 = false;
  showPassword = false;   
  invalid: boolean;
  constructor(public userManagerService: UserManagerService,
    public config: ConfigService,
    private translate: TranslateService, public modalService: ModalService) { }

  ngOnInit() {
    this.modalService.msg = ''
    this.userManagerService.passModal = true;
  }
  selectOption(option) {
    // console.log(option)
    this.option = option;
  }
  recoverPassword() {
    if((!this.phone || this.phone.trim() == '') && (!this.login || this.login.trim() == '') && this.config.login_required == 1 && this.option == "SMS"){
      this.modalService.msg = "username_and_phone_required"
      return
    }
    if(this.config.login_required == 1 && (!this.login || this.login.trim() == '') && this.option == "SMS") {
      console.log(this.phone, this.login)
      this.modalService.msg = "username_required"
      return
    }
    if((!this.phone || this.phone.trim() == '') && this.option == "SMS") {
      this.modalService.msg = "phone_required"
      return
    }
    if((!this.email || this.email.trim() == '') && this.option == "EMAIL") {
      this.modalService.msg = "email_required"
      return
    }
    this.modalService.msg = ''
    const params = {
      email: this.email,
      phone_number: (this.config.country_code.toString() + this.phone),
      login: this.login,
      option: this.option
    };
    this.userManagerService.isProcessing = true;
    this.userManagerService.verifyAccount(params).then(response => {
      if (response.hasOwnProperty('erc') && response.erc == '1') {
        this.user = response.data;
      }
      if (response.hasOwnProperty('erc') && response.erc == '0') {
        // this.modalService.isError = true;
        // this.modalService.isSuccess = false;
        // this.modalService.destroy();
        this.modalService.msg = response.msg;
        // this.modalService.showModal();
      }

      this.userManagerService.isProcessing = false;
    }).catch(data => {
      this.modalService.destroy();
      this.userManagerService.isProcessing = false;
      this.modalService.isError = true;
      this.modalService.isError = true;
      this.modalService.isSuccess = false;
      this.modalService.msg = 'connection_error';
      this.modalService.showModal();

    });
  }
  closeView() {
    this.modalService.destroy();
  }

  verifyPassword(){
    var pattern = /^(?=.{6,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/
    // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/

    var checkval = pattern.test(this.verificationData.password);
// console.log(checkval)
    if(!checkval) {
      this.invalid = true;
    } else {
      this.invalid = false;
    }
  }
  validate() {
    this.modalService.msg = ''
    // if (this.verificationData.str_Password_old == this.verificationData.str_Password) {
    //   this.modalService.msg = "old_pass_equal_new_passs";
    //   return;
    // }
    if (this.verificationData.password == this.user.str_Login) {
      this.modalService.msg = this.translate.instant("username_can_not_be_same_as_password");
      return;
    }
    if (this.verificationData.password != this.verificationData.password2) {
      this.modalService.msg = this.translate.instant("password_confirmation_incorrect");
      return;
    }
    const params = this.verificationData;
    params.agent_id = this.user.lg_AGENT_ID;
    this.userManagerService.isProcessing = true;
    this.userManagerService.resetPassword(params).then(data => {
      this.userManagerService.isProcessing = false;
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.isError = false;
        this.modalService.msg = data.msg;
        this.modalService.showModal();
       
      } else {
        
        // this.modalService.destroy();
        this.modalService.isError = true;
        this.modalService.msg = (data.msg && data.msg.includes('JSONObject["agent_id"]')) ? this.translate.instant('agentid_not_found') : data.msg;
        // this.modalService.showModal();
        
        // }
        // else{
        //   this.modalService.isError = true;
        //   this.modalService.msg = data.msg;
        // }
        // this.modalService.destroy();
        
        // this.modalService.showModal();
      }
    }).catch(err => {
      this.modalService.destroy();
      this.modalService.isError = true;
      this.modalService.msg = 'connection_lost';
      this.modalService.showModal();
     
    }).finally(() => {

      this.userManagerService.isProcessing = false;
    });
  }
  ngOnDestroy(){
    this.userManagerService.passModal = false;
    this.userManagerService.resetPass.next(0);
  }
}
