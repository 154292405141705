import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  partners =[
 {
    name:'parentalResp_fr',
 },
 {
  name:'DiZaPay',
},
{
  name:'ORANGE',
},
{
  name:'MOOV',
},
{
  name:'mobile-money',
},
{
  name:'VISAUBA',
},
]
  constructor(private router: Router) { }
  menus: Array<any> = [
    {
      name: 'about',
      id: '1',
      link: '/rules-page'
    }, {
      name: 'notice',
      id: '2',
      link: '/rules-page'
    },
    //  {
    //   name: 'faq',
    //   id: '3',
    //   link: ''
    // },
    // {
    //   name: 'limits',
    //   id: '4',
    //   link: '/rules-page'
    // },
     {
      name: 'play_responsibly',
      id: '5',
      link: '/rules-page'
    }
    , {
      name: 'ml_contacts',
      id: '6',
      link: '/rules-page'
    },
        {
          name: 'how_to',
          id: '7',
          link: '/rules-page',
        },
    // , {
    //   name: 'contact',
    //   id: '6',
    //   link: ''
    // }
    // , {
    //   name: 'depot-retrait',
    //   id: '7',
    //   link: ''
    // }
  ];

  ngOnInit() {
  }
  openMiscInfo(activeTab) {
    // console.log('open footer')
    this.router.navigate([activeTab.link], { queryParams: { tab: activeTab.id } })

  }
}
