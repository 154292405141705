import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  setCookie(cname, cvalue, time) {
    var d = new Date();
    d.setTime(d.getTime() + (time * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
   getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
   checkCookie(cname) {
    var user = this.getCookie(cname);
    if (user != "") {
      return true
    } else {
     return false
    }
  }
  public setCookieObject(name, value, exdays?) {

    exdays = exdays ? exdays : 60 * 24  

    this.setCookie(name, JSON.stringify(value), exdays)
  }
   deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  public delete_cookie(name) {
    document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=.', window.location.host.toString()].join('');
  }
  constructor() { }
  public save(key, value) {
    localStorage.setItem(key, typeof value == "string" ? value : JSON.stringify(value));
  }
  public get(key, value?) {
    return localStorage.getItem(key);
  }
  public getInt(key, dvalue): number {
    return Number(localStorage.getItem(key));
  }
  public getString(key, value): string {
    return localStorage.getItem(key);
  }
  public getObject(key, value): any {
    try {
      if (localStorage.getItem(key) != null) {
        return JSON.parse(localStorage.getItem(key));
      } else { return value; }
    } catch (err) {
      return value;
    }
  }
  public deleteItem(key) {
    return localStorage.removeItem(key);
  }
}
