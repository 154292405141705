import { PipesModule } from './../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { SidebarComponent } from './sidebar/sidebar.component';


@NgModule({
  declarations: [
   TicketDetailsComponent, MessageBoxComponent, SidebarComponent

  ], exports: [SidebarComponent],
  entryComponents: [TicketDetailsComponent,MessageBoxComponent],
  imports: [
    TranslateModule.forChild(),
    CommonModule,
    PipesModule,
    FormsModule
  ]
})
export class ComponentsModule { }
