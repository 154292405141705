import { ModalService } from './../../../providers/modal-service/ModalService';
import { ConfigService } from './../../../providers/config/config.service';
import { MyAccountService } from '../../../providers/my-account/my-account.service';
import { UserManagerService } from '../../../providers/services/userManager/user-manager.service';
import { Component, OnInit, ɵConsole } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { Route, Router, ResolveStart } from '@angular/router';
import { MyAccountComponent } from '../my-account.component';
declare var $: any;
let context;
@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  withdrawalAmt = '';
  noHandler: any;
  yesHander: any;
  conFirmMsg: string;
  depositAmt = '';
  depositCode = '';
  inPutPin = '';
  phoneNumber: any;
  otp = '';
  msg: any;
  status: any = 'D';
  suffixPhone = '';
  msgWithdrawal = '';
  msgDeposit = '';
  transactions: Array<any> = [];
  isProcessingWithdrawal = false;
  isProcessingDeposit = false;
  requireConfirmation = false;
  headers: Array<any> = [
    {
      name: 'deposit',
      id: 1
    },
    {
      name: 'withdrawal',
      id: 2
    },
    {
      name: 'history',
      id: 3
    }
  ];
  providers: any = {};
  paymentMethods: Array<any> = [];
  activeHeader: any;
  filteredAccnts: Array<any> = [];
  activeMethod: any;
  accountParams: any = {};
  fromDate: Date = new Date();
  toDate: Date = new Date();
  isLoading: boolean;
  procesingOTP: boolean;
  retrait: boolean;
  invalidNumber: boolean;
  invalidMessge: string;
  constructor(
    public accountService: MyAccountService,
    public configService: ConfigService,
    public translateService: TranslateService,
    public modalService: ModalService,
    private route : Router,
    public usermanagerService: UserManagerService) {
    context = this;
    if(this.usermanagerService.userData)
    this.accountParams = {
      uid: this.usermanagerService.userData.customer_ID,
      lgn: this.usermanagerService.userData.login,
      pin: this.usermanagerService.userData.str_PIN ? this.usermanagerService.userData.str_PIN : '',
      chl: '3',
      wtp: 'B',
      ref: '' + new Date().getTime(),
      id: this.usermanagerService.userData.external_payment_tef,
      acc: this.usermanagerService.userData.account_code,
      // acc:"CV9402",
      lang: this.usermanagerService.userData.lang ? this.usermanagerService.userData.lang : navigator.language.split('-')[0],
      opr: ''
    };

    this.usermanagerService.onLogIn.subscribe(data => {
      // console.log(context.modalService);
      context.modalService.destroy();
    });

  }
  sendOTP() {
        this.msgWithdrawal = undefined;
        this.msgWithdrawal = undefined;
        if (this.activeMethod.is_mobile && (isNaN(Number(this.phoneNumber)) || !this.phoneNumber)) {
          this.msg = this.translateMsg("invalid_phone_number");
          return;
        }
        if (isNaN(Number(this.withdrawalAmt)) || !this.withdrawalAmt) {
          this.msg = this.translateMsg("amt_must_be_greater_than") + (this.activeMethod.min ? this.activeMethod.min : 0)
          return;
        }
        if (Number(this.withdrawalAmt) < Number(this.activeMethod.min)) {
          this.msg = this.translateMsg("minimum_authorised_amt") + this.activeMethod.min
          return;
        }
        if (Number(this.withdrawalAmt) > Number(this.activeMethod.max)) {
          this.msg = this.translateMsg("max_authorised_amt") + this.activeMethod.max
          return;
        }
        const context = this;
        context.isProcessingWithdrawal = true;
        context.accountService.getOTP(this.withdrawalAmt).then(data => {
      if (data.erc == '1') {
        context.requireConfirmation = true;

          this.retrait = this.activeMethod.code == 'VISA UBA'? true : false;


      }
      else {
        this.msgWithdrawal = data.msg ? data.msg : this.translateService.instant('erreur lors de la transaction');
      }
      // console.log("data",data)
    }).catch(err => {
      console.error(err);
      this.isProcessingWithdrawal = false;
    }).finally(() => {
      // this.requireConfirmation = true;
      this.isProcessingWithdrawal = false;
    });

  }

  translateMsg(msg){
    return this.translateService.instant(msg)
  }
  switcHeader(header) {
      if (header.id === 2 && !this.usermanagerService.userData.profile_completed) {
        const msg = 'complete_signup_to_withdraw';
        this.modalService.openDialog('security_alert', msg, true, "").then(async data => {
          if (data.yesClicked) {
            this.accountService.activeAccountstab = 3;
            this.modalService.init(MyAccountComponent, { activeTab: 1 , mobileTab:3}, {});
          } else {
          }
        });
        return;
      }
    const context = this;
    this.activeHeader = header;
    if (header.id === 1) {
      this.accountService.getProviders(this.activeHeader.id).then(data => {
        context.selectMethod(this.accountService.paymentProviders[this.activeHeader.id][0]);
      });
    } else if (header.id === 2) {
      this.accountService.getProviders(this.activeHeader.id).then(data => {
        this.selectMethod(this.accountService.paymentProviders[this.activeHeader.id][0]);
      });
    } else if (header.id === 3) {
      setTimeout(() => {
        $('#dateFrom').datepicker({
          dateFormat: 'yy-mm-dd',
          onSelect: (newDate) => {
            context.fromDate = new Date(newDate);
          }
        });
        $('#dateTo').datepicker({
          dateFormat: 'yy-mm-dd',
          onSelect: (newDate) => {
            context.toDate = new Date(newDate);
          }
        });
      }, 500);
      const params = {
        uid: this.usermanagerService.userData.customer_ID,
        status: this.status,
      };
      this.getHistory(params);
    }

  }
  getHistory(params?: any) {
    if (!params) {
      params = {
        uuid: this.usermanagerService.userData.customer_ID,
        day: this.fromDate.getFullYear() + '-' + (this.fromDate.getMonth() + 1) + '-' + this.fromDate.getDate(),
        day2: this.toDate.getFullYear() + '-' + (this.toDate.getMonth() + 1) + '-' + this.toDate.getDate(),
        tp: this.status,
      };
    }
    this.transactions = [];
    this.isLoading = true;
    this.usermanagerService.getAccountTransactions(params).then(data => {
      this.transactions = data;
      this.isLoading = false;
    }).catch(err => {
      this.transactions = [];
      this.isLoading = false;
    }).finally(() => {

    });
  }
  ngOnInit() {
    const context = this;
    this.switcHeader(this.headers[0]);
    this.accountService.getUserBanks();
    this.usermanagerService.isProcessing = false;

  }

  selectMethod(method) {
    this.invalidNumber = false;
    this.activeMethod = method;
    this.msg = undefined;
    if (this.usermanagerService.userData && this.usermanagerService.userData.providers) {
      this.filteredAccnts = this.usermanagerService.userData.providers.filter(account => {
        return account.pvd == method.code;
      });
      // console.log('here are the filtered accounts', this.filteredAccnts)
    }

    this.phoneNumber = this.filteredAccnts && this.filteredAccnts.length > 0 ? this.filteredAccnts[0].accnt : undefined;
    if(this.phoneNumber && this.phoneNumber.length ){
      this.verifyPhoneNumber();
    }

  }
  closeConfirmMessage() {
    this.conFirmMsg = undefined;
    this.yesHander = undefined;
    this.noHandler = undefined;
  }
  showConfirmDialog() {


    this.conFirmMsg = 'confirm_mobile_withdrawal';
    this.yesHander = this.triggerWithdrawal;
    this.noHandler = this.closeConfirmMessage;
  }
  triggerWithdrawal() {
    this.msgWithdrawal = undefined;
    this.requireConfirmation = false;
    this.procesingOTP = true;
    this.conFirmMsg = undefined;
    if (isNaN(Number(this.withdrawalAmt)) || !this.withdrawalAmt) {
      this.msgWithdrawal = 'Le montant doit être supérieur à ' + this.activeMethod.min;
      return;
    }
    if (Number(this.withdrawalAmt) < Number(this.activeMethod.min)) {
      this.msgWithdrawal = 'Le montant minimum autorisé pour cette méthode est ' + this.activeMethod.min;
      return;
    }
    if (Number(this.withdrawalAmt) > Number(this.activeMethod.max)) {
      this.msgWithdrawal = 'Le montant maximum autorisé pour cette méthode est ' + this.activeMethod.max;
      return;
    }
      this.accountParams.amt = this.withdrawalAmt + '';
      this.accountParams.pdi = this.activeMethod.id;
      this.accountParams.pvd = this.activeMethod.pdv;
      this.accountParams.str_Pin = this.otp;
      this.accountParams.pin = this.otp;
      this.accountParams.phn = this.getPhoneNumber();
      // this.accountParams.phn =  "97777199"
      this.accountParams.opr = this.activeMethod.id + '';
      this.accountParams.sid = this.usermanagerService.userData.session,
      this.isProcessingWithdrawal = true;
      this.accountParams.pdvurl = this.activeMethod.code == 'LONACI' ? 'LONACI_WITHDRAWAL' : this.activeMethod.pdv.replace(' ', '') + '_WITHDRAWAL';
      // this.accountParams['pdvurl'] = this.activeMethod['pdv'].replace(' ', '') + '_WITHDRAWAL';
      this.otp = undefined;



      this.accountService.triggerWithdrawal(this.accountParams.pdvurl, this.accountParams).then(data => {
        if (!this.activeMethod.is_voucher) {
          console.log("hey")
          if (data.erc == '1' || (data[0] && data[0].erc == '1')) {
            context.modalService.destroy();
            this.modalService.msg = this.activeMethod.code == 'VISA UBA' ? data[0].ERROR_MESSAGE.split('.')[0] : this.configService.getErrorMessage('withdrawal_successful');
            this.modalService.title = 'Bravo !!!!!!';
            context.modalService.showModal();
            if (data.balances || (data[0] && data[0].balances)) {
              this.usermanagerService.updateUserAttribute('balances', data.balances ? data.balances : data[0].balances);
            }
          } else {
            this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE ? data.ERROR_MESSAGE : data[0].ERROR_MESSAGE);
          }

        } else {
          if (!data || !data.erc || data.erc == '0') {
            this.msgWithdrawal = this.configService.getErrorMessage(data.ERROR_MESSAGE ? data.ERROR_MESSAGE : data[0].ERROR_MESSAGE);
          } else {
            this.modalService.destroy();
            if (this.activeMethod.code.replace(' ', '') == 'SPORTCASHWITHDRAWAL' || this.activeMethod.code.replace(' ', '') == 'LONACI' || this.activeMethod.code.replace(' ', '') == 'SPORTCASH') {
              this.modalService.msg = this.translateService.instant('ml_code_sent_email');
            } else {
              this.modalService.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE ? data.ERROR_MESSAGE : data[0].ERROR_MESSAGE);
            }
            this.modalService.title = 'Bravo !!!!!!';

            this.modalService.showModal();
            if (data.balances || (data[0] && data[0].balances)) {
              this.usermanagerService.updateUserAttribute('balances', data.balances ? data.balances : data[0].balances);
            }
          }

        }

      }).catch(error => {
        // console.log(error)
        this.msg = 'verify_internet';
      }).finally(() => {
        this.isProcessingWithdrawal = false;
        this.requireConfirmation = false;
        this.procesingOTP = false;
      });
  }
  getPhoneNumber(){
    let res = this.phoneNumber + this.suffixPhone
    if(res && res.startsWith(this.configService.country_code)) {
      res = res.split(this.configService.country_code)[1];
    }
    return res;
  }
  closeModal() {
    this.requireConfirmation = false;
  }
  triggeDeposit() {
    this.msgDeposit = undefined;
    if (!this.activeMethod.is_voucher) {
      if (isNaN(Number(this.depositAmt)) || !this.depositAmt) {
        this.msg = 'Montant invalide ';
        return;
      }
      if (this.activeMethod.min && (Number(this.depositAmt) < Number(this.activeMethod.min))) {
        this.msg = 'Le montant minimum autorisé pour cette méthode est ';
        return;
      }
      if (this.activeMethod.max && (Number(this.depositAmt) > Number(this.activeMethod.max))) {
        this.msg = 'Le montant maximum autorisé pour cette méthode est ';
        return;
      }
      if (this.activeMethod.is_mobile && (isNaN(Number(this.phoneNumber)) || this.phoneNumber.trim() == '')) {
        this.msg = 'Numero de telephone invalide ';
        return;
      }
    }

    this.accountParams.amt = this.depositAmt + '';
    this.accountParams.code = this.depositCode;
    this.accountParams.phn = this.getPhoneNumber();
    // this.accountParams.phn = "97777199"
    this.accountParams.pdi = this.activeMethod.id;
    this.accountParams.opr = this.activeMethod.id + '';
    this.accountParams.pvd = this.activeMethod.pdv;
    this.isProcessingDeposit = true;
    this.accountParams.pdvurl = this.activeMethod.code == 'LONACI' ? 'LONACI_DEPOSIT' : this.activeMethod.pdv.replace(' ', '') + '_DEPOSIT';
    this.accountService.triggerDeposit(this.accountParams.pdvurl, this.accountParams).then(data => {
      if (data && data.length > 0) {
        if (data[0].erc == '1') {
          // this.msg = this.configService.getErrorMessage(data[0].ERROR_MESSAGE);
          // console.log(this.activeMethod.is_one_step)
          if (!this.activeMethod.is_one_step) {
            this.requireConfirmation = true;
            // data[0].ERROR_MESSAGE = "Veuillez confirmer la demande de retrait avec le code SMS envoye au client, Ref :1117184649463307412890206709244396-000015842"
            this.accountParams.transid = data[0].ERROR_MESSAGE.split('-')[1];
          } else {
            // this.msg = this.configService.getErrorMessage(data[0].ERROR_MESSAGE);
            this.modalService.destroy();
            this.modalService.msg = this.activeMethod.is_mobile ? data[0].ERROR_MESSAGE.split('-')[0] : 'deposited_successfully';
            this.modalService.title = this.translateService.instant('ml_success');
            this.modalService.openDialog(this.modalService.title, this.modalService.msg, false).then(res =>{
              this.usermanagerService.moveBack();
            });
            this.depositAmt = '';
            // this.msg = undefined;
            // this.modalService.destroy();
            this.msg = this.activeMethod.is_mobile ? data[0].ERROR_MESSAGE.toString().split('-')[0] : 'deposited_successfully';
            if (data[0].balances) {
              this.usermanagerService.updateUserAttribute('balances', data[0].balances);
            }
            // this.usermanagerService.redirect ? window.location.assign(this.usermanagerService.redirect.replaceAll('%2F', '/')) : '';
          }

        } else {
          this.msgDeposit = data[0].ERROR_MESSAGE && data[0].ERROR_MESSAGE.length > 0 ? data[0].ERROR_MESSAGE : 'transaction_failed';
        }
      }
      this.isProcessingDeposit = false;
    }).catch(error => {
      this.msg = 'verify_internet';
    }).finally(() => {
      this.isProcessingDeposit = false;
    });
  }
  initMsgs() {
    this.msg = undefined;
  }
  validateTransaction() {
    // tslint:disable-next-line: triple-equals
    if (!this.otp || this.otp.trim() == '') {
      return;
    }
    // console.log(this.accountParams.pdvurl,this.retrait, this.activeMethod.code )

    if ((this.activeMethod.code !== 'VISA UBA') || this.retrait) {
      this.triggerWithdrawal();
      this.retrait=false;
      return;
    }
    this.procesingOTP = true;
    this.accountParams.sms = this.otp;
    // this.accountParams.pdvurl = this.activeMethod.pdv.replace(' ', '') + '_DEPOSIT';
    this.accountService.validateOTP(this.accountParams).then(response => {
      this.procesingOTP = false;
      this.otp = '';
      const data = response[0];

      // tslint:disable-next-line: triple-equals
      if (data.erc == '1') {
        this.modalService.destroy();
        // this.modalService.msg = 'deposited_successfully';
        this.modalService.msg = data.ERROR_MESSAGE ? this.configService.getErrorMessage(data.ERROR_MESSAGE) : 'deposited_successfully' ;
        this.modalService.title = this.translateService.instant('ml_success');
        this.modalService.showModal();
        this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE) || undefined;
        if (data.balances) {
          this.usermanagerService.updateUserAttribute('balances', data.balances);
        }
      } else {
        this.requireConfirmation = false;
        this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE);
      }
    }).finally(() => {
      this.procesingOTP = false;
      this.otp = '';
    });
  }
  verifyPhoneNumber(){
    // console.log(this.activeMethod.mask);
    let mask = this.activeMethod.mask
    let length1 = mask && mask.indexOf('#') > -1  ? mask.split('#')[1]: this.configService.phoneNumberLength;
    var pattern = /^[0-9]+$/
    // // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/
    var checkval = pattern.test(this.phoneNumber);
    if(!checkval){
      this.invalidMessge ="invalid_character_in_phone_number";
    }
    let phoneLength = this.phoneNumber.toString().length
    if( length1 && (Number(phoneLength) !== Number(length1))){
      checkval =false;
      this.invalidMessge ="invalid_length";
    }
    if(!checkval) {
      this.invalidNumber = true;

    } else {
      let testMasks = mask && mask.indexOf('#')? mask.split('#')[0].split('-'): [];
      if(testMasks && testMasks.length>0)
     {
       for(let maskitem of testMasks){
        if (this.phoneNumber.toString().indexOf(maskitem) == 0)
        {return this.invalidNumber = false;}
        else{
          this.invalidNumber =true
          this.invalidMessge ="invalid_prov_code";
        }

      }
      } else this.invalidNumber =false
    }
  }
}

