import { ModalService } from '../../providers/modal-service/ModalService';
import { UserManagerService } from './../../providers/services/userManager/user-manager.service';
import { Component, OnInit, Input } from '@angular/core';
import { MyAccountService } from '../../providers/my-account/my-account.service';
import { ConfigService } from '../../providers/config/config.service';
declare var $ : any;
@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
    @Input() activeTab: number;
    @Input() mobileTab: number;
    myAccountItem = [{
        title: "acc_details",
        icon: "/assets/images/content-view-list.svg",
        tabID: 1,
    },
    {
        title: "my_bets",
        icon: "/assets/images/content-newspaper-1.svg",
        tabID: 2,
    },
    {
        title: "profile",
        icon: "/assets/images/user.svg",
        tabID: 3,
    },
    {
        title: "my_deposit",
        icon: "/assets/images/banking-money-receive-2.svg",
        tabID: 4,
    },
    {
        title: "my_withdrawals",
        icon: "/assets/images/banking-money-send-3.svg",
        tabID: 5,
    },
    {
        title: "winnings_withdrawl",
        icon: "/assets/images/coin-receive.svg",
        tabID: 6,
    },
    {
        title: "recharge_acc",
        icon: "/assets/images/content-newspaper-1.svg",
        tabID: 7,
    },
    {
        title: "payment_account",
        icon: "/assets/images/content-newspaper-1.svg",
        tabID: 8,
    },
    {
        title: "chang_password",
        icon: "/assets/images/lock-3.svg",
        tabID: 9,
    }
]
    constructor(
        public accountPanelServie: MyAccountService,
        public configService: ConfigService,
        public modalService: ModalService, public userManagerService: UserManagerService) { }

    ngOnInit() {
        console.log("initialising tab")
        this.accountPanelServie.activeAccountstab = this.activeTab;
        this.accountPanelServie.mobileActiveAccountstab = this.mobileTab;
        $('#modal-body').animate({ scrollTop: $(document).height() }, 'slow');
    }
    selectTab(tabId) {
        if (tabId === 6 && !this.userManagerService.userData.profile_completed) {
            const msg = 'complete_signup_to_withdraw';
            this.modalService.openDialog('security_alert', msg, true, "").then(async data => {
              if (data.yesClicked) {
                this.accountPanelServie.activeAccountstab = 3;
                this.modalService.init(MyAccountComponent, { activeTab: 1, mobileTab: 3 }, {});
              } else {
              }
            });
            return;
          }
        console.log(tabId);
        this.accountPanelServie.activeAccountstab = tabId;
        this.accountPanelServie.mobileActiveAccountstab = tabId;
        $('#modal-body').animate({ scrollTop: $(document).height() }, 'slow');
    }
    disconnectUser() {
        const context = this;
        const param = {
            sessionToken: context.userManagerService.userData.session,
        };
        context.userManagerService
            .logout(param)
            .then((data) => { })
            .finally(() => {
                // this.websocketService.disconnectRabbitAuthenticatedUser()
                this.userManagerService.cleanLocalSession();
            });
    }
}
