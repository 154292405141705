
import { HttpService } from "./../../../wrappers/Http";
import { Injectable, ɵConsole } from "@angular/core";
import { StorageService } from "../../storage.service";
import { BehaviorSubject } from "rxjs";
import { ModalService } from "../../modal-service/ModalService";
import { ConfigService } from "../../config/config.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from '../../auth.service';
import { Partner } from "src/app/interfaces/partners";
import { ResetPassowrdComponent } from "src/app/components/reset-passowrd/reset-passowrd.component";
@Injectable({
  providedIn: "root",
})
export class UserManagerService {
  verifyUser = false;
  userID = "";
  userphone = "";
  urlSessionId = "";
  lastActiveTime: any;
  userManagementService: any;
  idleInterval: any;
  userIdleTime: any;
  checkIdletime: boolean = false;
  partners: Array<Partner> = new Array(0);
  products: any[];
  images: any[];
  expireTime = 5;
  timeout: boolean = true;
  params: any;
  loginData: { header: string; data: string };
  redirect: any;
  forgottenpwrd: boolean;
  closeModal = true;
  passModal = false;
  resetPass = new BehaviorSubject(0);
  // resetPass = false;
  moveBack() {
    this.redirect ? window.location.assign(this.redirect) : "";
  }
  confirmpaymentAccount(requestData: {
    sid: any;
    otp: any;
    id: any;
  }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestHandler
        .authPost(
          this.configService.PROXIES.userService ,
            this.configService.EndPoints.confirmOtpPayment,
          requestData
        )
        .then((data) => {
          if (data.erc && data.erc == "1") {
            resolve(data);
          } else reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  saveAccount(request: { sid: any; pvd: any; accnt: any }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestHandler
        .authPost(
          this.configService.PROXIES.userService ,
            this.configService.EndPoints.savePaymentAccnt,
          request
        )
        .then((data) => {
          if (data.erc && data.erc == "1") {
            resolve(data);
          } else reject(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  onLogIn = new BehaviorSubject(0);
  securityQuestions: Array<any>;
  defaultLanguages: Array<any> = [
    {},
    { name: "english", value: "en", },
    { name: "french", value: "fr", },
  ];
  defaultSexes: Array<any> = [
    {},
    { name: "male", value: "M", },
    { name: "female", value: "F", },
  ];
  validProviders = [];

  isProcessing = false;
  logingIn = false;
  credsValid = true;
  rememberMe = false;
  validationPatterns = {
    redirectDomain: /:\/\/([^\/]+)/
  }
  private _userData: any;
  private _username: any;
  private _password: any;
  private _error_msg: any;
  private _countries = [];
  public dialingCodes: Array<any> = [];
  private _showChangePasswordAlert: boolean;
  public get showChangePasswordAlert(): boolean {
    return this._showChangePasswordAlert;
  }
  public set showChangePasswordAlert(value: boolean) {
    this._showChangePasswordAlert = value;
  }
  public get error_msg(): any {
    return this._error_msg;
  }
  public set error_msg(value: any) {
    this._error_msg = value;
  }
  private lang: any = navigator.language;
  constructor(
    public requestHandler: HttpService,
    private translate: TranslateService,
    public storageService: StorageService,
    private configService: ConfigService,
    private modalService: ModalService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  public get countries(): any {
    return this._countries;
  }
  public set countries(value: any) {
    this._countries = value;
  }
  public get username(): any {
    return this._username;
  }
  public set username(value: any) {
    this._username = value;
  }

  public get password(): any {
    return this._password;
  }
  public set password(value: any) {
    this._password = value;
  }

  public get userData(): any {
    return this._userData;
  }
  public set userData(value: any) {
    this._userData = value;
  }
  startIdleWatch() {
    this.idleInterval = setInterval(() => {
      this.userIdleTime = this.userIdleTime + 1;
      //  console.log(this.userIdleTime, this.checkIdletime)
      //  if(this.checkIdletime){
      //   if (this.userIdleTime < this.expireTime ) {

      //     this.renewSession();

      //    }
      //    else{
      //      this.userData = [];
      //    }
      //  }
    }, 60000);
    document.addEventListener("click", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("mousemove", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("keypress", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("change", () => {
      this.userIdleTime = 0;
    });
    document.addEventListener("scroll", () => {
      this.userIdleTime = 0;
    });
  }
  resendSMS(params: { phone: any }) {
    return this.requestHandler.Post(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.resendVerificationCodeBySMS,
      params
    );
  }
  verifyPhone(params: { phone: string }) {
    return this.requestHandler.Hget(
      this.configService.PROXIES.userService,
      this.configService.EndPoints.verifyPhone,
      params
    );
  }
  confirmCodeRequest(data): Promise<any> {
    return this.postRequest(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.confirmCodeURL,
      data
    );
  }
  postRequest(api, url, data): Promise<any> {
    return new Promise((resolve, reject) => {
      this.requestHandler
        .Post(api, url, data)
        .then((response) => {
          if (response && response.erc === "1") {
            resolve(response.data);
          } else {
            reject(response.msg ? response.msg : "ml_validation_failed");
          }
        })
        .catch((err) => console.log(err));
    });
  }
  // register(userData): Promise<any> {
  //   this.isProcessing = true;
  //   // if (userData.hasOwnProperty('birthday')) {
  //   //   userData.birthday = new Date(userData.birthday).getTime();
  //   // }
  //   return this.requestHandler.get_request(
  //     this.configService.PROXIES.userService + this.configService.EndPoints.register,
  //     userData
  //   );
  // }
  validatePassword(word) {
    var pattern = /^(?=.{6,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/;
    //  var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{6,}$/
    // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/
    var invalid;

    var checkval = pattern.test(word);
    // console.log(checkval)
    if (!checkval) {
      invalid = false;
    } else {
      invalid = true;
    }
    return invalid;
  }
  quickSignup(userData): Promise<any> {
    this.isProcessing = true;

    return this.requestHandler.Post(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.quickSignup,
      userData
    );
  }
  register(userData): Promise<any> {
    this.isProcessing = true;
    // if (userData.hasOwnProperty('birthday')) {
    //   userData.birthday = new Date(userData.birthday).getTime();
    // }
    return this.requestHandler.Post(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.register,
      userData
    );
  }
  async login(isMobile?): Promise<boolean> {
    this.logingIn = true;
    const params = {
      login: this.username,
      pwd: this.password,
      lang: this.lang,
      channel: 3,
    };
    this.error_msg = "";
    return new Promise((resolve, reject) => {
      this.requestHandler
        .Post_auth(
          this.configService.PROXIES.userService ,
            (!isMobile
              ? this.configService.EndPoints.login_with_phonenumber
              : this.configService.EndPoints.login),
          params
        )
        .then((response: any) => {

          if (response.hasOwnProperty("erc") && response.erc === "0") {

            this.userData = response.data;
            this.configService.tempData = response.data;
            // if (this.rememberMe) {
            // let url = window.location.href;
            // window.location.href =
            //   url.split("?")[0] + "?session=" + this.userData.session;
            this.replaceurl({session:null})
            this.storageService.setCookie(
              "session",
              this.userData.session,
              this.configService.expiry
            );
            // this.storageService.save('user', this.userData);            // this.storageService.save('user', this.userData);
            // }
            this.onLogIn.next(1);
            this.saveToken();
            this.startIdleWatch();
            resolve(true);
          } else {
            // if (response[0] && response[0].str_Statut) {
            //   if (response[0].sessionToken) {
            //     console.log("session active");
            //     this.modalService
            //       .openDialog(
            //         "security_alert",
            //         "ml_more_than_one_session",
            //         true,
            //         response[0].device
            //       )
            //       .then(async (data) => {
            //         if (data.yesClicked) {
            //           this.userData = {};
            //           this.userData.customer_ID = response.data.lgAgentId;
            //           await this.renewSession().then(() => {
            //             resolve(this.userData.hasOwnProperty("session"));
            //           });
            //         } else {
            //           this.cleanLocalSession();
            //         }
            //       });
            //   } else {
            //     this.modalService
            //       .openDialog("security_alert", response[0].str_Statut, false)
            //       .then((data) => {
            //         reject(false);
            //       });
            //     // resolve(false);
            //     return;
            //   }
            // }
            // tslint:disable-next-line:triple-equals
            // if (response && response.str_Statut) {

            if (response.sessionToken) {
              // console.log('session active');
              this.modalService
                .openDialog(
                  "security_alert",
                  "ml_more_than_one_session",
                  true,
                  response.device
                )
                .then(async (data) => {
                  if (data.yesClicked) {
                    this.userData = {};
                    this.userData.customer_ID = response.customer_ID;

                    await this.renewSession(params).then(() => {
                      resolve(this.userData.hasOwnProperty("sessionToken"));
                    });
                  } else {
                    this.cleanLocalSession();
                  }
                });
            }
            //  else if (response.str_Statut) {
            //   this.modalService
            //     .openDialog('security_alert', response.str_Statut, false)
            //     .then((data) => {
            //       reject(false);
            //     });
            //   // resolve(false);
            //   return;
            // }
            // }
            if (response.data && response.data.suspended) {
              this.modalService
                .openDialog("security_alert", "suspend_account_msg", true)
                .then(async (data) => {
                  if (data.yesClicked) {
                    this.forgottenpwrd = true;
                    await resolve(false);
                  } else reject(false);
                });
              // resolve(false);
              return;
            }
            // tslint:disable-next-line:triple-equals

            if (response.msg == "ml_more_than_one_session") {
              this.modalService
                .openDialog(
                  "security_alert",
                  "ml_more_than_one_session",
                  true,
                  response.data.device
                )
                .then(async (data) => {
                  if (data.yesClicked) {
                    this.userData = {};
                    this.userData.customer_ID = response.data.customer_ID;
                    await this.renewSession(params).then(() => {
                      resolve(this.userData.hasOwnProperty("session"));
                    });
                  } else {
                    this.cleanLocalSession();
                  }
                });
            } else if (response.msg == "ml_erc_client_blocked") {
              // console.log('ml_erc_client_blocked');
              this.modalService
                .openDialog("Login Error", response.msg, false)
                .then((data) => {
                  resolve(false);
                });
            }else if(response.erc == '239') {
              this.resetPasswordPrompt();
            }
            else {
              this.error_msg = response.msg;
              this.userData = undefined;
              resolve(false);
            }
          }

          this.logingIn = false;
        })
        .catch((data) => {
          this.userData = undefined;
          this.logingIn = false;
          if (data.hasOwnProperty("ok") && !data.ok) {
            // console.log(data.error);
            this.error_msg = data.error.message;
          } else {
            this.error_msg = data.msg;
            reject(false);
          }
        });
    });
  }
  replaceurl(params){
    this.router.navigate([], {
        relativeTo: this.route,
        queryParams: params,
        replaceUrl: true,
      });
  }

  forgotPassword(){
    this.modalService.destroy();
    this.resetPass.next(1);
    // this.modalService.init(ResetPassowrdComponent, {}, {});
  }
  resetPasswordPrompt(){
    this.modalService.openDialog("security_alert", "reset_password", true)
    .then((data)=>{
      if (data.yesClicked){
        this.forgotPassword();
      }
      else{
        this.cleanLocalSession();
      }
    })
  }
  logout(params): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.requestHandler
        .authPost(
          this.configService.PROXIES.userService ,
            this.configService.EndPoints.logout,
          params
        )
        .then((response: any) => {
          let url = window.location.href;
          window.location.href = url.split("?")[0];
          this.storageService.setCookie(
            "session",
            "",
            this.configService.expiry
          );
          this.storageService.save("user", null);
          this.userData = undefined;
          this.timeout = false;
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // updateUser(): Promise<any> {
  //   // this.isProcessing = true;

  //   let dt = new Date(this.userData.birthday);
  //   this.userData.birthday =
  //     dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();
  //   const params = {
  //       str_Login: this.userData.login,
  //       str_Account_Code: this.userData.account_code,
  //       str_Address : this.userData.address,
  //       str_balances : this.userData.balances,
  //       str_Birthdate : this.userData.birthday,
  //       str_CNI : this.userData.cni,
  //       str_lg_Agent_Id: this.userData.customer_ID,
  //       str_Email : this.userData.email,
  //       str_FNAME : this.userData.first_name,
  //       str_Lang : this.userData.lang,
  //       str_LNAME : this.userData.last_name,
  //       str_Nationalite: this.userData.nationality,
  //       notif_Channels: this.userData.notif_Channels,
  //       str_Phone_Number : this.userData.phone_number,
  //       str_Sexe: this.userData.sex,
  //       str_Session: this.userData.session,
  //       str_Town: this.userData.town,
  //       str_Statut: this.userData.status,
  //       str_channel: 3
  //     };
  //   return this.requestHandler.Post(
  //     this.configService.PROXIES.userService + this.configService.EndPoints.updateProfile,
  //     params
  //   );
  // }
  updateUser(userData?): Promise<any> {
    // this.isProcessing = true;

    const params = this.buildObjct(userData ? userData : this.userData);
    let dt = new Date(params.str_Birthdate);

    params.str_Birthdate =
      dt.getDate() + "/" + (dt.getMonth() + 1) + "/" + dt.getFullYear();

    return this.requestHandler.authPost(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.updateProfile,
      params
    );
  }
  buildObjct(userdata) {
    const params = {
      str_Login: userdata.login.trim(),
      str_Account_Code: userdata.account_code.trim(),
      str_Address: userdata.address,
      str_balances: userdata.balances,
      str_Birthdate: userdata.birthday,
      str_CNI: userdata.cni.trim(),
      str_lg_Agent_Id: userdata.customer_ID,
      str_Email: userdata.email.trim(),
      str_FName: userdata.first_name,
      str_Lang: userdata.lang,
      str_LName: userdata.last_name,
      str_Nationalite: userdata.nationality,
      notif_Channels: userdata.notif_Channels,
      str_Phone_Number: userdata.phone_number.trim(),
      str_Sexe: userdata.sex,
      str_Session: userdata.session,
      str_Town: userdata.town,
      str_Statut: userdata.status,
      str_channel: 3,
      sid: userdata.sid? userdata.sid : ''
    };
    return params;
  }
  isUserProfileComplete() {
    const userDetials = this.userData;
    if (
      (userDetials.hasOwnProperty("email") &&
        userDetials.email.trim() === "") ||
      userDetials.login === userDetials.phone_number ||
      (userDetials.hasOwnProperty("first_name") &&
        userDetials.first_name.trim() === "") ||
      (userDetials.hasOwnProperty("last_name") &&
        userDetials.last_name.trim() === "") ||
      (userDetials.hasOwnProperty("sex") && userDetials.sex.trim() === "") ||
      (userDetials.hasOwnProperty("lang") &&
        userDetials.lang.toString() === "") ||
      (userDetials.hasOwnProperty("birthday") &&
        new Date(userDetials.birthday).getFullYear() + 18 >
          new Date().getFullYear()) ||
      (userDetials.hasOwnProperty("cni") && userDetials.cni.trim() === "")
    ) {
      return false;
    } else {
      return true;
    }
  }
  loadActivePartners() {

    return this.requestHandler
      .get_request(
        this.configService.PROXIES.userService ,
          this.configService.EndPoints.partners,
        {}
      )
      .then((res) => {
        this.partners = res.data;
      });
  }
  changePassword(passwords): Promise<any> {
    this.isProcessing = true;
    return this.requestHandler.authPost(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.changePassword,
      passwords
    );
  }
  changePin(pins): Promise<any> {
    this.isProcessing = true;
    return this.requestHandler.authPost(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.changePin,
      pins
    );
  }
  buildUserData(userdata) {
    this.userData = {
      login: userdata.str_Login,
      account_code: userdata.str_Account_Code,
      str_Address: userdata.address,
      balances: userdata.balances,
      birthday: userdata.dt_AGENT_BIRTHDAY,
      customer_ID: userdata.lg_AGENT_ID,
      str_Lang: userdata.lang,
      str_LName: userdata.last_name,
      str_Nationalite: userdata.nationality,
      notif_Channels: userdata.notif_Channels,
      phone_number: userdata.str_Phone_Number,
      str_Sexe: userdata.sex,
      session: userdata.session,
      status: userdata.str_Statut,
      can_bet: userdata.can_bet,
      lastConnection_Date: userdata.lastConnection_Date,
      isExpired: userdata.isExpired,
      isQuickSignup: true,
    };
  }
  getTransactions(params: any): Promise<any> {
    return this.requestHandler.get_auth(
      this.configService.PROXIES.userService,
      // 'http://10.12.1.178:45756/'+
        this.configService.EndPoints.transactions,
      params
    );
    // return
  }
  getTransactionDetails(params: any, provider) {

    // url = provider.toLowerCase() == "sportcash" ? url + params.ref : url;
    return this.requestHandler.get_auth(
      this.configService.PROXIES[provider.toLowerCase()] ,
      this.configService.EndPoints[provider.toLowerCase() + "_transactions"],
      params
    );
  }

  loadSlides() {
    // const perpage = 20;
    // this.searchdata = searchdata;
    // let param = {page:0, perpage: 20, currentPage: 0 };
    return this.requestHandler.get_request(
      this.configService.PROXIES.sportcash , "sport/sliders",
      { channel_id: "LONACI", status: "true" }
    );
  }
  filterPartners(params: any): Promise<any> {
    // console.log('params')
    return this.requestHandler.get_request(
      this.configService.PROXIES.userService
      ,
       "partners/all",
      params
    );
  }
  filterProducts(params: any) {
    return this.requestHandler.get_request(
      this.configService.PROXIES.userService
      , "products/all",
      params
    );
  }
  getAccountTransactions(params: any): Promise<any> {
    return this.requestHandler.get_auth(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.payment_transactions,
      params
    );
    return;
  }
  verifyEmail(params: { email: string }) {
    return this.requestHandler.Hget(
      this.configService.PROXIES.userService,
      this.configService.EndPoints.verifyEmail,
      params
    );
  }
  verifyUserName(params: { username: string }) {
    return this.requestHandler.Hget(
      this.configService.PROXIES.userService,
      this.configService.EndPoints.verifyUserName,
      params
    );
  }
  checkProvider(phone_number) {
    var validPhone = false;
    if (!this.validProviders.length) return true;
    for (let prov of this.validProviders) {
      // console.log(prov + "   " + phone_number.toString().substring(0,2))
      if (phone_number.toString().startsWith(prov)) {
        validPhone = true;
        return validPhone;
      }
    }

    // if(!validPhone){
    //   this.modalService.isError = true;
    //   this.modalService.title = 'sorry';
    //   this.modalService.msg = 'mobile_prov_unvailable';
    //   this.modalService.showModal();
    //   // this.registrationForm.controls['password'].setErrors({ 'incorrect': true });
    //   return validPhone;
    // }
    return validPhone;
  }

  verifyAccount(params: any): Promise<any> {
    return this.requestHandler.Post_auth(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.recover1,
      params
    );
  }
  resetPassword(params: any): Promise<any> {
    return this.requestHandler.Post_auth(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.recover2,
      params
    );
  }

  updateUserAttribute(attrib: string, value: any) {
    this.userData[attrib] = value;
    // console.log(this.userData);
    if (this.storageService.getObject("user", false)) {
      this.storageService.save("user", this.userData);
    }
  }
  getSecurityQuestions() {
    let params = {};
    params["channel"] = 7;
    params["versioncode"] = 3;
    // params["date_jr"] = " ";
    this.requestHandler
      .Hget(this.configService.PROXIES.userService , "user/security-questions", params)
      .then((response: any) => {
        this.securityQuestions = response.data;
        this.securityQuestions.unshift({});
      });
  }
  triggerCashout(refCode: any): Promise<any> {
    return this.requestHandler.Hget(
      this.configService.PROXIES.turf_gateway,
      this.configService.EndPoints.cashout + "/" + refCode + "",
      {}
    );
  }
  completeCashout(id: any, cashoutvalue: number) {
    const params = {
      bid: id,
      uid: this.userData.customer_ID,
      amt: cashoutvalue,
    };
    return this.requestHandler.PostRequest(
      this.configService.PROXIES.turf_gateway,
      this.configService.EndPoints.completeCashout +
        this.requestHandler.parseParams(params),
      params
    );
  }
  getCountryList(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      fetch("../assets/countries.json")
        .then((res) => res.json())
        .then((res) => {
          this.countries = res;
          this.countries.unshift({});
          resolve(true);
        })
        .catch((err) => {
          reject(false);
          // console.log("error is ", err);
        });
    });
  }
  resendActivation(value: any) {
    return this.requestHandler.Post(
      this.configService.PROXIES.userService ,
        this.configService.EndPoints.resendMail,
      { email: value }
    );
  }

  refreshSession() {
    this.requestHandler
      .Post(this.configService.PROXIES.userService, "user/session/renew", {
        agent: this.userData.customer_ID,
      })
      .then((data) => {
        if (!data.hasOwnProperty("erc") || data.erc == "0") {
          // console.log("invalid User");
        }else if(data.erc == "239"){
          this.resetPasswordPrompt()
        } 
        else {
          this.userData.session = data.data;
          this.storageService.save("user", this.userData.session);
        }
      });
  }
  async renewSession(params:any={}) {
    params.agent=this.userData.customer_ID,
    params.channel= 3;
    return Promise.resolve(
      this.requestHandler
        .Post_auth(
          this.configService.PROXIES.userService, "user/session/renew-api",
          params
        )
        .then((data: any) => {
          if (
            (data.hasOwnProperty("erc") && data.erc == "0") ||
            data.erc == 0
          ) {
            this.userData = data.data;

            this.configService.tempData = data.data;
            // console.log(this.userData)
            // this.onLogIn.next(1);

            // if (this.rememberMe) {
            //   this.storageService.save('user', this.userData.customer_ID);
            // }
            let url = window.location.href;
            url = url.split("?")[0] + "?session=" + this.userData.session;
            history.pushState({}, null, url);
            // this.storageService.setCookie(
            //   "session",
            //   this.userData.session,
            //   this.configService.expiry
            // );
            this.saveToken();
            // this.storageService.save('user', this.userData);
          } else if(data.erc == '239'){
            this.resetPasswordPrompt();
          } 
          else {
            // this.userData = undefined
            // console.log('invalid User');
          }
        })
      // .finally(() => {
      //   setTimeout(() =>{
      //     this.timeout = true;
      //   }, 500)

      // })
    );
  }
  authSession(id) {
    if (!id) return;
    return Promise.resolve(
      this.requestHandler
        .get_auth(this.configService.PROXIES.userService , "user/login/" + id, {refresh_token: this.configService.USER_AUTHORISATION.refresh_token})
        .then((data: any) => {
          if (
            (data.hasOwnProperty("erc") && data.erc == "0") ||
            data.erc == 0
          ) {
            this.userData = data.data;
            this.configService.tempData = data.data;
            this.onLogIn.next(1);
            // if (this.rememberMe) {
            //   this.storageService.save('user', this.userData.customer_ID);
            // }

            // let url = window.location.href;
            // window.location.href =
            //   url.split("?")[0] + "?session=" + this.userData.session;
            // this.saveToken(this.configService.USER_AUTHORISATION);
            this.saveToken();
            this.storageService.setCookie(
              "session",
              this.userData.session,
              this.configService.expiry
            );
            // this.storageService.deleteItem("session");
            // this.storageService.save('user', this.userData.session);
            this.startIdleWatch();
          } else if(data.hasOwnProperty("erc") && data.erc == '239'){
            this.resetPasswordPrompt();
          } 
          else {
            // console.log('session Expired');
            this.userData = undefined;
            // this.modalService.msg = "ml_session_expired";
            // this.modalService.showModal();
            // this.storageService.save('user', '');
            this.storageService.save("session", null);
            // this.storageService.save('user', null);
          }
        })
        .catch((err) => {
          console.log(err);
        }).finally(()=>{
          this.replaceurl({session:null})

        })
    );
  }
  cleanLocalSession() {
    this.resetPass.next(0);
    this.onLogIn.next(0);
    this.storageService.deleteCookie("session");
    this.storageService.deleteItem("auth")
    this.userData = undefined;
  }

  setLoggedInUserUrlParams(){
    if(this.userData && this.configService.USER_AUTHORISATION)
    return `session=${this.userData.session}&token=${this.configService.USER_AUTHORISATION.access_token}&refresh-token=${this.configService.USER_AUTHORISATION.refresh_token}&expire_at=${this.configService.USER_AUTHORISATION.expiry}&token_duration=${this.configService.USER_AUTHORISATION.token_duration}`
  }

  public downloadGuide(url, name?) {
    let pdfReqBody = {
      url: url,
      title: name,
    };

    this.requestHandler
      .get_request(url, pdfReqBody, { responseType: "blob" })
      .then((res) => {
        let blob = new Blob([res], { type: "application/pdf" });
        let pdfUrl = window.URL.createObjectURL(blob);

        var PDF_link = document.createElement("a");
        PDF_link.href = pdfUrl;
        //   TO OPEN PDF ON BROWSER IN NEW TAB
        // window.open(pdfUrl, '_blank');
        //   TO DOWNLOAD PDF TO YOUR COMPUTER
        PDF_link.download = `${name.replaceAll(" ", "-")}`;
        PDF_link.click();
      });
  }
  updateUserBalance() {
    let params = {
      lg_Client_ID: this.userData.customer_ID,
    };
    this.requestHandler
      .authPost(
        this.configService.PROXIES.userService ,
          this.configService.EndPoints.user_balance,
        params
      )
      .then((res) => {
        console.log(res);
      });
  }

  setTokenData(object){
    if(object['token'] && object.token_duration && object['refresh-token'] && object['expire_at']){
      this.configService.USER_AUTHORISATION = {access_token: decodeURIComponent(object['token'])  ,refresh_token: decodeURIComponent(object['refresh-token']) , expiry:object['expire_at'], token_duration:object.token_duration}
    }
  }

  async refreshUsersContext(userID, token?) {

    if(this.configService.USER_AUTHORISATION.access_token == "")
    return
    return this.requestHandler
      .get_auth(
        this.configService.PROXIES.userService,
        'user/auth/' + (userID ? userID : this.userData.session),
        {refresh_token: this.configService.USER_AUTHORISATION.refresh_token}
      )
      .then((data) => {
        if (!data.hasOwnProperty('erc') || data.erc != '0' || data.erc != '239') {
          throw new Error('not logged in');
        } else if(data.hasOwnProperty("erc") && data.erc == '239'){
          this.resetPasswordPrompt();
        } 
        else {
          if (data.hasOwnProperty('data') && data.data.session != null) {
            this.userData = data.data;
            if (
              this.userData.hasOwnProperty('lang') &&
              this.userData.lang.length > 1 && !this.configService.lang
            ) {
                this.configService.lang = (this.userData.lang + '')
                .toString()
                .replace(/lang_/gi, '');
              this.translate.use(this.configService.lang);


            }
            // this.saveNewSession(this.userData.session);
            this.saveToken(this.configService.USER_AUTHORISATION);
          }
        }
      });
    // }
  }


  saveNewSession(sessionID: any) {
    if (this.urlSessionId && this.urlSessionId.length) {
      this.router.navigateByUrl(
        this.router.url.replace(this.urlSessionId, sessionID)
      );
    }
    this.storageService.setCookie('session', sessionID, this.configService.expiry);
    this.storageService.save('auth',  this.configService.USER_AUTHORISATION)

  }
  saveToken(userData?){
    userData = userData ? userData : this.userData
    this.configService.USER_AUTHORISATION = {access_token:(userData.access_token.indexOf('Bearer')>-1 ? "" : 'Bearer ') + userData.access_token, expiry: new Date(userData.expiry || userData.expire_at), refresh_token:userData.refresh_token, token_duration:userData.token_duration }
    this.saveNewSession(this.userData.session);

    setTimeout(() => {
      if(this.userData && this.userData.customer_ID && this.configService.USER_AUTHORISATION.refresh_token){
        this.authService.getTokenRequest()
        .then(res=>{
          let userDta:any = this.configService.USER_AUTHORISATION;
          userDta.expire_at = this.configService.USER_AUTHORISATION.expiry;
          this.saveToken(userDta);
        })
      }

    }, userData.token_duration || this.userData.token_duration);
  }
}
