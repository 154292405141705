import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import * as CryptoJS from "crypto-js";
import { ConfigService } from "../providers/config/config.service";
import { Observable } from "rxjs";

@Injectable()
export class HttpService {

  headers: HttpHeaders | { [header: string]: string | string[]; };
  options: any;
  authorization: string;

  constructor(private http: HttpClient, private configs: ConfigService,) {
    // this.setHeaders('withToken');
    this.configs.$config.subscribe(res => {
      this.authorization = `Basic ${window.btoa(this.configs.AuthParams.username + ':' + this.configs.AuthParams.secret)}`
    })


    // logger.log("basic auth headers",window.btoa(this.configs.AuthParams.username + ':' + this.configs.AuthParams.secret),this.configs.AuthParams.username + ':' + this.configs.AuthParams.secret )
  }

  get(api: any, params: any): Promise<any> {
    params.channel_id = params.channel_id ? params.channel_id : this.configs.channel;

    params.lang = params.lang ? params.lang : this.configs.lang
    params = this.parseParams(params);
    const headers = this.getHeaders('', api, params.split('?')[1])
    const options = { headers };
    // this.logger.log(api);
    try {
      const url = this.configs.PROXIES.gateway + api + params;
      return new Promise((resolve, reject) => {
        this.http.get(url, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      });
    }
  }

  getFile(url: any, params: any): Observable<any> {
    params.channel_id = 3;
    // let url ='';
    // if(api.indexOf('/') > -1 ){
    //  url = api;
    //  api = api.split(this.configs.PROXIES.IMAGES_REPO)[1]
    // }else{
    url = this.configs.PROXIES.IMAGES_REPO + url;

    // }

    const headers = this.getHeaders('', this.configs.PROXIES.IMAGES_REPO.split('?')[0], "")
    // this.logger.log(this.configs.PROXIES.IMAGES_REPO);
    try {
      return this.http.get(url, { headers, responseType: 'blob' })
    } catch (err) {
      // this.logger.log(err);
    }
  }
  get_auth(api: any, url:any, params: any): Promise<any> {

    params.lang = params.lang || "fr"
    params = this.parseParams(params);
    const headers = this.getHeaders('auth', url, params.split('?')[1]);
    const options = { headers };

    try {
      const reqUrl = api + url + params;
      return new Promise((resolve, reject) => {
        this.http.get(reqUrl, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      });
    }
  }
  get_live_request(api, url: any, params: any): Promise<any> {
    api = api + url;
    params.channel_id = params.channel_id || 3;


    params.lang = params.lang ? params.lang : this.configs.lang
    params = this.parseParams(params);


    const headers = this.getHeaders('', url, params.split('?')[1])
    const options = { headers };

    try {
      url = api + params;
      return new Promise((resolve, reject) => {
        this.http.get(url, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      }).catch((err: HttpErrorResponse) => {
        //console.log('An error occurred:', err.error);
      });
    }
  }

  get_request(api, url: any, params: any): Promise<any> {
    api = api + url;
    // params.channel_id = params.channel_id || this.configs.channel;
    params.lang = params.lang ? params.lang : "fr"
    params = this.parseParams(params);
    const headers = this.getHeaders('', url, params.split('?')[1])
    const options = { headers };
    try {
      url = api + params;
      return new Promise((resolve, reject) => {
        this.http.get(url, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      }).catch((err: HttpErrorResponse) => {
        //console.log('An error occurred:', err.error);
      });
    }
  }

  get_no_auth(url: any, params: any): Promise<any> {
    params.channel_id = this.configs.channel;

    params.lang = params.lang ? params.lang : this.configs.lang
    //console.log(url)
    try {
      document.body.style.cursor = 'wait';
      url = url + this.parseParams(params);
      return new Promise((resolve, reject) => {
        this.http.get(url)
          .subscribe(data => {
            document.body.style.cursor = 'default';
            resolve(data);
          }, error => {
            document.body.style.cursor = 'default';
            reject(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      });
    }
  }

  _get(api: any, params: any): Promise<any> {
    params.channel_id = this.configs.channel;
    params.lang = params.lang || this.configs.lang

    this.setHeaders('withToken');
    try {
      const url = api + this.parseParams(params);
      //console.log(url)
      return new Promise((resolve, reject) => {
        this.http.get(url, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer 084678ad-2f42-4e5a-954f-dbe675f4b1d5'
          }
        })
          .subscribe(data => {
            resolve(data);
          }, error => {
            resolve(error);
          });
      });
    } catch (err) {
      return new Promise(reject => {
        reject(err);
      });
    }
  }


  Hget(host: any, api: any, params: any): Promise<any> {
    // params.channel_id = 3;
    // this.setHeaders('withToken');
    params.lang = params.lang ? params.lang : "fr"
    const me = this;
    const url = host + api + this.parseParams(params);
    const headers = this.getHeaders('', api, this.parseParams(params).split('?')[1]);
    const options = { headers }
    // console.debugerror("url", url);
    //console.log(url)
    return new Promise((resolve, reject) => {
      this.http.get(url, options)
        .subscribe(data => {
          // console.debugerror("data",JSON.stringify(data));
          resolve(data);
        }, error => {
          // console.debugerror("data",JSON.stringify(error));
          reject(error);

        });

    });
  }


  parseParams(data: any): any {
    const arr = Object.keys(data);
    let queryParams = arr.length > 0 ? '?' : '';
    for (let i = 0; i < arr.length; i++) {
      if (data[arr[i]] != (undefined || null))
        queryParams = queryParams + arr[i] + '=' + data[arr[i]] + (i < arr.length - 1 ? '&' : '');
    }
    return queryParams;
  }

  parseAuthParams(data: any): any {
    const arr = Object.keys(data);
    let queryParams = '';
    for (let i = 0; i < arr.length; i++) {
      queryParams = queryParams + arr[i] + '=' + data[arr[i]] + '&';
    }
    return encodeURI(queryParams);
  }


  /************************Post Request*******************************/

  PostRequest(host: any, api: any, params: any, headers?:any): Promise<any> {
    const url = host + api;
    params.channel = 3;
    params.lang = params.lang ? params.lang : this.configs.lang;
    const requestHeaders = headers ? headers : null;

    return new Promise((resolve, reject) => {
      this.http.post(url, params, { 'headers': requestHeaders })
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }

  postFileUpload(host: any, api: any, params: any, formData?: any): Promise<any> {
    params.channel_id = this.configs.channel;
    params.lang = params.lang ? params.lang : this.configs.lang

    const url = host + api;
    return new Promise((resolve, reject) => {
      this.http.post(url, formData) //{headers:headers}
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }

  // ajax_post

  Post(api, url: any, params: any, header?): Promise<any> {
    params.channel = this.configs.channel + '';
    params.channel_id = this.configs.channel + "";
    params.lang = params.lang ? params.lang : "fr"

    // const me = this;
    const headers = this.getHeaders(header, url, params)
    const options = { headers };
    url = api + url;
    return new Promise((resolve, reject) => {
      this.http.post(url, params, options)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }
  authPost(api, url: any, params: any, header?): Promise<any> {
    params.lang = params.lang ? params.lang : "fr"
    params.channel_id = 3;
    params.channel = 3;
    // const me = this;
    const headers = this.getHeaders('auth', url, params)
    url = api + url;
    const options = { headers };
    return new Promise((resolve, reject) => {
      // this.http.post(url, params)
      this.http.post(url, params, options)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }
  authPost_test(api, url: any, params: any, header?): Promise<any> {
    params.lang = params.lang ? params.lang : "fr"
    params.channel_id = 3;
    params.channel = 3;
    // const me = this;
    // const headers = this.getHeaders('auth', url, params)
    url = api + url;
    // const options = { headers };
    return new Promise((resolve, reject) => {
      // this.http.post(url, params)
      this.http.post(url, params)
        .subscribe(data => {
          resolve(data);
        }, error => {
          reject(error);
        });
    });
  }
  authQueryParamsPost(api, url: any, params: any, header?): Promise<any> {

    params.lang = params.lang || this.configs.lang
    params.channel_id = 3;
    params.channel = 3;
    params = this.parseParams(params);
    // const me = this;
    const headers = this.getHeaders('auth', url, params.split('?')[1]);
    // const options = { headers };

    // try {
      // const url = this.configs.PROXIES.userService + api + params;




      // params.lang = params.lang ? params.lang : this.configs.lang
      // params.channel_id = 3;
      // params.channel = 3;
      // const me = this;
      // const headers = this.getHeaders('auth', url, params)
      url = api + url + params;
      const options = { headers };
      return new Promise((resolve, reject) => {
        // this.http.post(url, params)
        this.http.post(url,null, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    }
  Post_auth(api, url: any, params: any): Promise < any > {
      //console.log(params);
      const route = api + url;
      params.lang = params.lang ? params.lang : this.configs.lang
    params.channel_id = this.configs.channel;
      params.channel = this.configs.channel;

      const me = this;
      // const headers = new HttpHeaders({
      //   'Content-Type': 'application/json; charset=UTF-8',
      //   //  'Content-Signature': this.configs.AuthParams.username + ':' +  this.getBase64Hash(JSON.stringify(this.configs.AuthParams.username+ url+ params))
      //    'Content-Signature': this.configs.AuthParams.username + ':' +  this.getBase64Hash(JSON.stringify(params))

      // });
      const headers = this.getHeaders("login", url, params);
      const options = { headers };
      return new Promise((resolve, reject) => {
        this.http.post(route, params, options)
          .subscribe(data => {
            resolve(data);
          }, error => {
            reject(error);
          });
      });
    }

    Post_no_auth(url: any, params: any): Promise < any > {
      params.lang = params.lang ? params.lang : this.configs.lang
    //   params.token = CommonUtilities.getSessionStorage('token');
    document.body.style.cursor = 'wait';
      return new Promise((resolve, reject) => {
        this.http.post(url + this.parseParams(params), params) //{headers:headers}
          .subscribe(data => {
            resolve(data);
            document.body.style.cursor = 'default';
          }, error => {
            resolve(error);
            document.body.style.cursor = 'default';
          });
      });
    }


    PUT_NO_AUTH(url: any, params: any): Promise < any > {
      this.setHeaders('');
      // params.token =  CommonUtilities.getSessionStorage('token');
      params.lang = params.lang ? params.lang : this.configs.lang
    const me = this;
      document.body.style.cursor = 'wait';
      return new Promise((resolve, reject) => {
        this.http.put(url, params, {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer 084678ad-2f42-4e5a-954f-dbe675f4b1d5'
          }
        })
          .subscribe(data => {
            document.body.style.cursor = 'default';
            resolve(data);
          }, error => {
            document.body.style.cursor = 'default';
            // console.debugerror("data",JSON.stringify(error));
            resolve(error);
          });
      });
    }



    ajax_post(url, params) {
      params.lang = params.lang ? params.lang : this.configs.lang
      const token = '084678ad-2f42-4e5a-954f-dbe675f4b1d5';
      try {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            return Promise.resolve(this.responseText);

          }
        };
        xhttp.open('POST', url, true);
        xhttp.send(params);

        // return $.ajax({
        //     url: url,
        //     type: 'POST',
        //     dataType: 'json',
        //     headers: {
        //         'Authorization': 'Bearer ' + token,
        //     },
        //     data: params,
        // });
      } catch (error) {
        console.error('Error', error);
      }
    }

    host_delete(host: any, api: any, param: any): Promise < any > {

      const me = this;
      const url = host + api + '/' + param;
      // console.debugerror("url",url);
      return new Promise((resolve, reject) => {
        this.http.delete(url, me.options)
          .subscribe(data => {
            // console.debugerror("data",JSON.stringify(data));
            resolve(data);
          }, error => {
            // console.debugerror("data",JSON.stringify(error));
            resolve(error);

          });

      });
    }

    setHeaders(type): void {

      this.headers = new HttpHeaders();
      // let token = this.shared.token ? this.shared.token: "084678ad-2f42-4e5a-954f-dbe675f4b1d5";
      const token = '084678ad-2f42-4e5a-954f-dbe675f4b1d5';
      this.headers.append('Content-Type', 'application/json');
      this.headers.append('Accept', 'application/json');
      if(type === 'withToken') {
      this.headers.append('Authorization', 'Bearer ' + token);
    }
    this.options = {
      headers: this.headers
    };
  }
  getHeaders(type, url?, params?) {

    if (!(typeof params === 'string' || params instanceof String)) {
      params = JSON.stringify(params)
    }
    // console.log(this.configs.AuthParams.username + '/v2/' + url + params);
    switch (type) {
      case 'login':
        return new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8',

          'Content-Signature': this.configs.AuthParams.username + ':' + this.getBase64Hash(this.configs.AuthParams.username + '/v2/' + url + params),

          // 'Authorization': this.authorization

        });
      case 'auth':
        return new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8',

          'Content-Signature': this.configs.AuthParams.username + ':' + this.getBase64Hash(this.configs.AuthParams.username + '/v2/' + url + params),

          // 'auth': 'true'
          'Authorization': this.getToken()

        });
      case 'refreshToken':
        // console.log(this.configs.AuthParams.username + '/'+ url + params)
        return new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8',

          'Content-Signature': this.configs.AuthParams.username + ':' + this.getBase64Hash(this.configs.AuthParams.username + '/' + url + params),


        });

      default:
        return new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8',
          'Content-Signature': this.configs.AuthParams.username + ':' + this.getBase64Hash(this.configs.AuthParams.username + '/v2/' + url + params),
          // 'Authorization': this.authorization

        });
    }
  }
  getToken() {
    // if(this.configs.USER_AUTHORISATION && this.configs.USER_AUTHORISATION.expiry.getTime() > Date.now()){
    return this.configs.USER_AUTHORISATION.access_token
    //     }
    // this.getTokenRequest().then(res=>{
    //   return this.configs.USER_AUTHORISATION.access_token

    // })


  }
  getBase64Hash(params: string) {
    const hash = CryptoJS.HmacSHA256(params, this.configs.AuthParams.RequestHmacKey);
    return CryptoJS.enc.Base64.stringify(hash);
  }
  // getToken(){
  //   if(!this.configs.USER_AUTHORISATION || this.configs.USER_AUTHORISATION.expiry.getTime() < Date.now()){

  //   }
  // }



}
