
import { UserManagerService } from './services/userManager/user-manager.service';
import { TimeHandlerService } from './time-handler.service';
import { Injectable, ɵConsole } from '@angular/core';
import * as SockJS from "sockjs-client"
import * as Stomp from 'stompjs';
import * as _ from 'lodash';
import { SkeletonServiceService } from '../components/skeleton-service.service';
import { ConfigService } from './config/config.service';
import { ModalService } from './modal-service/ModalService';
import { Subject } from 'rxjs';
import { RABBIT_CONFIG } from 'src/environments/environment';
@Injectable()
export class WebsocketService {

  stompClient: any;
  stompclient: any;
  cagnoteGames: any;
  getCagnote: Subject<[]> = new Subject();
  message: any;
  partants: any;
  checkIdletime = false;
  constructor(

    private configs: ConfigService,
    private userServices: UserManagerService,
    private modalService: ModalService,
    private skeletonServiceSkeletonService: SkeletonServiceService,
    private timeService: TimeHandlerService) {
    this.userServices.onLogIn.subscribe(data => {
      if (data) {
        this.connect()
        this.userServices.saveToken();
      }
      else {
        this.disconnectRabbitAuthenticatedUser()
      }

    });
  }

  private subject: any;
  subScriptionlist: any = {};

  validTypes: any = ['push-newtimelineevent', 'push-data', 'push-newodds', 'push-newmatch', 'push-betstop', 'push-newodds'];
  public connect() {
    // if (!this.stompclient) {
    //   const ws = new WebSocket(this.configs.PROXIES.Rabbit_Address);
    //   this.stompClient = Stomp.over(ws);
    // }
    let ws = new SockJS(this.configs.PROXIES.Rabbit_Address);
    // const ws = new WebSocket(this.configs.PROXIES.Rabbit_Address);
    // const accws = new WebSocket(PROXIES.AccRabbit_Address);
    // this.stompClient = Stomp.over(ws);
    this.stompClient = Stomp.over(ws);
    // const _this = this;
    this.connectRabbit(this);
    this.stompClient.debug = null;
    // this.connectRabbit(this);

    // return this.subject;
  }
  public connect_session() {
    if (!this.stompclient) {
      // const ws = new WebSocket(this.configs.PROXIES.Rabbit_Address_user_session);
      let ws = new SockJS(this.configs.PROXIES.Rabbit_Address);
      this.stompclient = Stomp.over(ws);
    }

    this.connectRabbitSession(this);
  }

  public disconnect() {
    if (this.stompClient) {
      this.stompClient.disconnect(() => {

      });
    }
    if (this.stompclient) {
      this.stompclient.disconnect(() => {

      });
    }
    return this.subject;
  }
  async disconnectRabbitAuthenticatedUser() {
    if (this.stompClient) {
      if (this.stompclient.connected) {
        this.stompclient.unsubscribe('userQueue');
      }
    }

  }
  public connectRabbitAuthenticatedUser(context?) {
    const me = this;
    if (this.stompClient.connected) {
      this.stompClient.subscribe('/exchange/betradarfeedsuof/betradarfeedsuof.client-listeners.status', (m) => {
        const reversedText = m.body.split('').join('');
        me.processMessage(reversedText);
      });
      if (this.userServices.userData) {
        me.stompClient.subscribe('/exchange/betradarfeedsuof/flexbet.agent.' + this.userServices.userData.str_Account_Code, (m) => {
          const reversedText = m.body.split('').join('');
          me.processMessage(reversedText);
        }, { id: "userQueue" });
      }
    } else {
      this.stompClient.connect('root', 'admin', (frame) => {
        this.stompClient.subscribe('/exchange/betradarfeedsuof/betradarfeedsuof.client-listeners.status', (m) => {
          const reversedText = m.body.split('').join('');
          me.processMessage(reversedText);
        });
        if (this.userServices.userData) {
          me.stompClient.subscribe('/exchange/betradarfeedsuof/flexbet.agent.' + this.userServices.userData.str_Account_Code, (m) => {
            const reversedText = m.body.split('').join('');
            me.processMessage(reversedText);
          });
        }

      }, (message) => {
        setTimeout(() => { me.connect(); }, 1000);

      }, '/');
    }
  }
  public subscribe(tail?) {
    if (!this.configs.lastmessagetime) {
      this.configs.lastmessagetime = new Date();
    }

    let route = '/exchange/betradarfeedsuof/betradarfeedsuof.client-listeners';
    const context = this;
    // route = tail ? route + '.' + tail  : route;
    route = tail ? route + '.' + tail + '.' + (this.skeletonServiceSkeletonService.viewInContext == 'LIVE' ? 'live' : 'pre') + '.#' : route;
    if (this.stompClient.connected) {
      this.subScriptionlist[tail] = this.stompClient.subscribe(route, (m) => {
        const reversedText = m.body.split('').join('');
        context.processMessage(reversedText);
      });
      // let rout2e = '/exchange/betradarfeedsuof/betradarfeedsuof.client-listeners';
      // rout2e = tail ? rout2e + '.' + tail : rout2e;
      // this.stompClient.subscribe(rout2e, (m) => {
      //   const reversedText = m.body.split('').join('');
      //   context.processMessage(reversedText);
      // });
    }
    // let rout3e = '/exchange/betradarfeedsuof/betradarfeedsuof.client-listeners';
    // rout3e = rout3e + '.' + tail + '.' + 'live' + '.#'
    // // rout2e = tail ? rout3e + '.' + tail : rout3e;
    // this.stompClient.subscribe(rout3e, (m) => {
    //   const reversedText = m.body.split('').join('');
    //   context.processMessage(reversedText);
    // });


  }
  public unsubscribe(tail?) {

  }
  private connectRabbit(me) {
    this.stompClient.debug = null;
    // this.stompClient.connect('sportcash_ws_user', 'US$tIYOtO3EY', (frame) => {

    //   this.stompClient.subscribe('/exchange/flexbet_dashboard/*.*.two_race', (m) => {
    //     const reversedText = m.body.split('').join('');
    //     me.processMessage(reversedText);
    //   });
    // }, (message) => {
    //   // console.log('message', message)
    //   setTimeout(() => { me.connect(); }, 5000);

    // }, '/');
    // let me = this;
    // this.logger.log("reaching")
    this.stompClient.connect({}, (frame) => {
      // this.logger.log("subscibing to topic/messages")
      me.stompClient.subscribe("/topic/messages", function (m) {

        const reversedText = m.body.split('').join('');

        // me.logger.log("message received", JSON.stringify(m.body))
        me.processMessage(reversedText);

      });
      this.subscribeFeedData(this);

    }, (message) => {
      // this.logger.log("Connection failed", message);
      setTimeout(() => { me.connect(); }, 5000);

    });

  }
  
  private connectRabbitSession(context) {
    // stompClient.debug = null;
    let me = this;
    // this.logger.log("reaching")
    this.stompClient.connect({}, (frame) => {
      // this.logger.log("subscibing to topic/messages")
      me.stompClient.subscribe("/topic/messages", function (m) {

        const reversedText = m.body.split('').join('');

        // me.logger.log("message received", JSON.stringify(m.body))
        me.processMessage(reversedText);

      });
      this.subscribeFeedData(this);

    }, (message) => {
      // this.logger.log("Connection failed", message);
      setTimeout(() => { me.connect(); }, 5000);

    });
  }

  subscribeFeedData(context) {
    let param = { "exchange": "betradarfeedsuof", "routine": "betradarfeedsuof.client-listeners", queue: "" }
    this.sendGlobal(param)
  }

  sendGlobal(message) {

    // this.logger.log("SENDING MESSAGE TO RABBIT", message)
    if (this.stompClient && this.stompClient.connected) {
      this.stompClient.send("/app/chat", {}, JSON.stringify(message));
      // this.logger.log("Message Sent")
    }
    // else{
    //   setTimeout(() => {
    //     this.sendGlobal(message);
    //   }, 5000);
    // }
  }

  private connectRabbitSession_old(me) {
    this.userServices.checkIdletime = false;
    this.stompclient.debug = true;
    if (!this.stompclient.connected)
      this.stompclient.connect(RABBIT_CONFIG.USER, RABBIT_CONFIG.PASSWD, (frame) => {
        this.subscribeToSessionExchange();
      }, (message) => {

        setTimeout(() => { me.connectRabbitSession(me); }, 5000);

      }, '/');
    else
      this.subscribeToSessionExchange();
    // } else {
    //   setTimeout(() => { me.connect_session(); }, 5000);

    // }
  }
  subscribeToSessionExchange() {
    if (this.stompclient.connected)
      // if (this.userServices.userData && this.userServices.userData.account_code) {
      //   this.stompclient.subscribe(`/exchange/${RABBIT_CONFIG.EXCHANGE}/${RABBIT_CONFIG.QUEUE}.${this.userServices.userData.account_code}`, (m) => {
      //     const reversedText = m.body.split('').join('');
      //     // let message = JSON.parse(reversedText);
      //     // console.log("rabbit message", message)
      //     this.processMessage(reversedText)


      //   });
      // }
      if (this.userServices.userData) {
        let param = { exchange: this.configs.RABBIT_CONFIG.EXCHANGE, routine: this.configs.RABBIT_CONFIG.QUEUE + this.userServices.userData.account_code }
        this.sendGlobal(param)
      }
  }
  public disconnectUser() {
    if (this.stompclient) {
      this.stompclient.disconnect(() => {

      });
    }
    return this.subject;

  }

  async processMessage(rawMsg) {
    this.configs.lastmessagetime = new Date();


    if (rawMsg.indexOf('hello received') > -1 || rawMsg.indexOf('message received')>-1)
      return
    this.configs.lastmessagetime = new Date();


    let message = JSON.parse(rawMsg);
    if (typeof message === 'string')
      message = JSON.parse(rawMsg);
    if (typeof message === 'string')
      return; 

    if (message.hasOwnProperty('data') && message.data.hasOwnProperty('isExpired') && message.data.isExpired) {
      if (this.userServices.userData.session == message.data.session) {
        if (message.isForced) {
          // console.log('force logout')
          this.userServices.logout({ sessionToken: this.userServices.userData.session });
          this.disconnectUser();
        } else {
          if (this.userServices.userIdleTime < this.userServices.expireTime) {

            this.userServices.renewSession();

          }
          else {
            this.userServices.userData = undefined;
            this.disconnectUser();
          }

        }
      }

      else {
        this.userServices.logout({ sessionToken: this.userServices.userData.session });
        this.disconnectUser();
      }



    }
    if (message.hasOwnProperty('data') && message.type == "balance") {
      this.userServices.userData.balances = message.data.balances
    }

  }



  processplatformStatus(message: any) {
    this.configs.bet_activated = message.data.status;
  }
  updateBalance(message: any) {
    this.userServices.updateUserAttribute('balances', message.data.balances);
  }

  updateMissingfields(outcome: any, outcome2: any) {
    const Keys = Object.keys(outcome);
    Keys.forEach(key => {
      if (!outcome2.hasOwnProperty(key)) {
        outcome2[key] = outcome[key];
      }
    });
  }
}
