import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { channel, defaults, EndPoints, expiry, GAME_TYPES, limits, TurfEndpoints } from 'src/app/configs';
import { AuthParams, RABBIT_CONFIG } from 'src/environments/environment';
import { ERROR_LIB } from './ERROR_LIB';
import { token } from 'src/app/interfaces/token';
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public LIMITS: any;
  GAME_TYPES: any;
  window: any = window;
  gameDate: string;
  USSD_APP_ID: string;
  USER_DEFAULTS: any;
  partnerUrl: string = "https://pmu.lonacionline.ci/#/turf/";
  private _BL_ALLOW_LOGIN_PHONE_NUMBER: any;
  private _USER_AUTHORISATION: token = {access_token: "", refresh_token:"", expiry:new Date()};
  STR_MISS_CI_PIN: any;
  NewPartners: any[] = [];
  minLegalAge = 18;
  public get BL_ALLOW_LOGIN_PHONE_NUMBER(): any {
    return this._BL_ALLOW_LOGIN_PHONE_NUMBER;
  }
  public set BL_ALLOW_LOGIN_PHONE_NUMBER(value: any) {
    this._BL_ALLOW_LOGIN_PHONE_NUMBER = value;
  }

  public get site_name() {
    return this._site_name;
  }
  public set site_name(value) {
    this._site_name = value;
  }
  public get USER_AUTHORISATION(): token {
    return this._USER_AUTHORISATION;
  }
  public set USER_AUTHORISATION(value: token) {
    this._USER_AUTHORISATION = value;
  }
  open(partner) {
    let me = this;
    if (partner.url.toLowerCase().indexOf('miss-ci') > -1 ) {
      return me.isMobile() ? window.location.href = this.PROXIES.missCiMobileSite : this.router.navigateByUrl(partner.url)
      // return me.isMobile() ? window.location.href = this.PROXIES.missCiMobileSite : this.router.navigateByUrl('miss-ci')
      // if(me.isMobile()) window.location.href = this.PROXIES.missCiMobileSite;
      // else this.router.navigateByUrl(partner.url)
      // return;
    }
   let url = partner.url.includes('?')? partner.url + '&': partner.url+'?';
    // url = "http://10.12.1.186:4201?"
    url = url + this.setLoggedInUserUrlParams();
    url = this.decorateUrl(url)
    window.location.href = url;
  }
  setLoggedInUserUrlParams(){
    // if(this.getUsedata() && this.USER_AUTHORISATION)
    // return `session=${this.getUsedata().session}&token=${this.USER_AUTHORISATION.access_token}&refresh-token=${this.USER_AUTHORISATION.refresh_token}&expire_at=${this.USER_AUTHORISATION.expiry}&token_duration=${this.USER_AUTHORISATION.token_duration}`
    if(this.getUsedata())
    return `session=${this.getUsedata().session}`
  }
  decorateUrl(urlString) {
    var ga = this.window[this.window['GoogleAnalyticsObject']];
    var tracker;
    if (ga && typeof ga.getAll === 'function') {
      tracker = ga.getAll()[0]; // Uses the first tracker created on the page
      urlString = (new this.window.gaplugins.Linker(tracker)).decorate(urlString, true, true);
    }
    // debugger;
    return urlString;
  }
  public get country_code() {
    return this._country_code;
  }
  public set country_code(value) {
    this._country_code = value;
  }
  public get ctrl(): Array<any> {
    return this._ctrl;
  }
  public get phoneNumberLength() {
    return this._phoneNumberLength
  }
  public set phoneNumberLength(value) {
    this._phoneNumberLength = value
  }
  public get quick_signup_enabled() {
    return this._quick_signup_enabled
  }
  public set quick_signup_enabled(value) {
    this._quick_signup_enabled = value
  }
  public get login_required() {
    return this._login_required
  }
  public set login_required(value) {
    this._login_required = value
  }
  public set ctrl(value: Array<any>) {
    this._ctrl = value;
  }
  public get bet_activated() {
    return this._bet_activated;
  }

  public set bet_activated(value) {
    this._bet_activated = value;
  }

  public get system_kill_time() {
    return this._system_kill_time;
  }

  public set system_kill_time(value) {
    this._system_kill_time = value;
  }

  public get lastmessagetime() {
    return this._lastmessagetime;
  }

  public set lastmessagetime(value) {
    this._lastmessagetime = value;
  }

  public get show_tax() {
    return this._show_tax;
  }
  public set show_tax(value) {
    this._show_tax = value;
  }
  public get usePromoCode() {
    return this._usePromoCode
  }
  public set usePromoCode(value) {
    this._usePromoCode = value;
  }
  public get max_event() {
    return this._max_event;
  }
  public set max_event(value) {
    this._max_event = value;
  }
  public get min_amount_bonus() {
    return this._min_amount_bonus;
  }
  public set min_amount_bonus(value) {
    this._min_amount_bonus = value;
  }

  public get max_combined_odds() {
    return this._max_combined_odds;
  }
  public set max_combined_odds(value) {
    this._max_combined_odds = value;
  }

  public get aply_tax_based_winning() {
    return this._aply_tax_based_winning;
  }
  public set aply_tax_based_winning(value) {
    this._aply_tax_based_winning = value;
  }
  public get tax_based_winning() {
    return this._tax_based_winning;
  }
  public set tax_based_winning(value) {
    this._tax_based_winning = value;
  }



  public get tax_active() {
    return this._tax_active;
  }
  public set tax_active(value) {
    this._tax_active = value;
  }


  public get stake_step() {
    return this._stake_step;
  }

  public set stake_step(value) {
    this._stake_step = value;
  }

  public get max_stake() {
    return this._max_stake;
  }
  public set max_stake(value) {
    this._max_stake = value;
  }

  public get max_pot_win() {
    return this._max_pot_win;
  }
  public set max_pot_win(value) {
    this._max_pot_win = value;
  }
  public get min_possible_stake() {
    return this._min_possible_stake;
  }
  public set min_possible_stake(value) {
    this._min_possible_stake = value;
  }


  public get allow_simple_Bets() {
    return this._allow_simple_Bets;
  }
  public set allow_simple_Bets(value) {
    this._allow_simple_Bets = value;
  }


  public get bonus() {
    return this._bonus;
  }
  public set bonus(value) {
    this._bonus = value;
  }

  public get tax() {
    return this._tax;
  }
  public set tax(value) {
    this._tax = value;
  }
  public get odds_precision() {
    return this._odds_precision;
  }
  public set odds_precision(value) {
    this._odds_precision = value;
  }

  public get amountprecision() {
    return this._amountprecision;
  }
  public set amountprecision(value) {
    this._amountprecision = value;
  }

  public get coupon_validity() {
    return this._coupon_validity;
  }
  public set coupon_validity(value) {
    this._coupon_validity = value;
  }
  public get currency() {
    return this._currency;
  }
  public set currency(value) {
    this._currency = value;
  }

  constructor(private http: HttpClient, private router: Router) {

  }

  private _currency = 'XOF';
  private _country_code = '+225';
  private _phoneNumberLength = 10;
  // private _currency = 'XOF';
  private _coupon_validity = '2';
  private _amountprecision = 0.01;
  private _odds_precision = 2;
  private _tax = 25;
  private _bonus: any = {};
  private _allow_simple_Bets = false;
  private _min_possible_stake = 0;
  private _max_pot_win = 300000;
  private _max_stake = 10000000;
  private _stake_step = 50;
  private _tax_active;
  private _quick_signup_enabled = 0;
  private _login_required = 0;

  private match_kill_time = 15000;
  private _tax_based_winning = 1000000000000000;
  private _max_event;
  private _usePromoCode = 0;
  private _useRefAgentCode = 0;

  private _min_amount_bonus;
  private _aply_tax_based_winning = false;
  private _max_combined_odds;
  private _show_tax = false;
  private _ctrl: Array<any> = [];
  private _lastmessagetime;
  private _system_kill_time = 20000;
  private _bet_activated = true;
  private _site_name = "SPORTCASH";
  PROXIES: any = {};
  RABBIT_CONFIG: any = {};
  EndPoints: any = {};
  expiry: any;
  channel: any;
  lang: string;
  TurfEndpoints: any = {};
  $config: Subject<any> = new Subject();
  tempData: any = {};
  AuthParams: any;
  saveUserdata(userdata) {
    Object.assign(this.tempData, userdata)
  }
  getUsedata() {
    return this.tempData;
  }
  public getErrorMessage(code) {
    for (const key in ERROR_LIB) {

      if (ERROR_LIB[key] === code) {
        return key;
      }
    }

    return code;
  }
  loadConfigs() {
    this.RABBIT_CONFIG = RABBIT_CONFIG;
    this.TurfEndpoints = TurfEndpoints;
    this.GAME_TYPES = GAME_TYPES;
    this.LIMITS = limits
    this.EndPoints = EndPoints;
    this.expiry = expiry;
    this.channel = channel;
    this.USER_DEFAULTS = defaults;
    this.EndPoints = EndPoints;
    // this.RABBIT_CONFIG = environment.theme.RABBIT_CONFIG;
    // this.contact = environment.theme.countryInfo.contact;
    this.AuthParams = AuthParams;

    return new Promise((resolve, reject) => {
      this.http.get('assets/config/config.json').subscribe(
        (res: any) => {
          this.PROXIES = res.PROXIES;
          this.gameDate = res.dateJeu;
          this.NewPartners = res.NEW_PARTNERS
          this.$config.next(res);
          this.$config.complete();
          this.USSD_APP_ID = res.ussdid
          resolve(res);
        });
    });
  }
  public get useRefAgentCode() {
    return this._useRefAgentCode;
  }
  public set useRefAgentCode(value) {
    this._useRefAgentCode = value;
  }
  static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if (control.value && (control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true }
    }

    return null;
  }

  isMobile() {
    let ua = navigator.userAgent;
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      ) || window.innerWidth <= 425
    );
  }
}
