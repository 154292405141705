import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';

@Component({
  selector: 'app-reset-pw',
  templateUrl: './reset-pw.component.html',
  styleUrls: ['./reset-pw.component.scss']
})
export class ResetPwComponent implements OnInit {
  invalid: boolean;
  msg: string;
  viewoldPw: boolean;
  viewnewPW: boolean;
  viewConfirmPW: boolean;
  password: any = {str_Password2:'',
  str_Password : "", str_Password_old: ""
};

  constructor( public configService: ConfigService,
    public modalService: ModalService, public usermanagerService: UserManagerService) { }

  ngOnInit() {
  }
  initMsgs() {
    this.msg = undefined;
  }
  verifyPassword() {
    var pattern = /^(?=.{6,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/
    // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/

    var checkval = pattern.test(this.password.str_Password);
    // console.log(checkval)
    if (!checkval) {
      this.invalid = true;
    } else {
      this.invalid = false;
    }
    console.log(this.invalid)
  }
  changePasswords() {
    if (this.password.str_Password_old == this.password.str_Password) {
      this.msg = "old_pass_equal_new_passs";
      return;
    }
    if (this.password.str_Password == this.usermanagerService.userData.login) {
      this.msg = "username_can_not_be_same_as_password";
      return;
    }
    if (this.password.str_Password != this.password.str_Password2) {
      this.msg = "password_confirmation_incorrect";
      return;
    }
    this.password.str_Login = this.usermanagerService.userData.login;
    this.usermanagerService.isProcessing = true;
    this.usermanagerService.changePassword(this.password).then(data => {
      this.usermanagerService.isProcessing = false;
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.usermanagerService.showChangePasswordAlert = false;
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.msg = "password_updated";
        this.modalService.showModal();
        this.usermanagerService.userData.isExpired = false;
      } else {
        this.msg = data.msg;
      }


    }).catch(data => {
      this.modalService.destroy();
      this.modalService.isSuccess = false;
      this.modalService.isError = false;
      this.modalService.msg = 'connection_error';
      this.modalService.showModal();
    })
      .finally(() => {
        this.usermanagerService.isProcessing = false;
      });
  }
}
