export const Contact = {
  tel: "+216 36 38 68 25",
  email: "contact@bet216.tn",
  fax: "+216 36 38 68 25",
  addresse:
    "Immeuble le 45,Bloc A 3eme Etage Bureau A 3.3 , Rue du Japon , Montplaisir 1073- Tunis-Tunisie",
};

export const EndPoints = {
  resendActivation: "user/resendActivation",
  slides: "system/sliders",
  userManager: "UserManager",
  register: "customer-service/new",
  quickSignup: "customer-service/register",
  resendMail: "user/resendActivation",
  login: "user/login-api",
  login_with_phonenumber: "user/phonenumber-login-api",
  partners: "partners/active",
  logout: "user/logout",
  logoutCustomer: "user/close-session",
  transactions: "transac-service/bets",
  pmu_transactions: "ser_customer_transaction",
  armel_transactions: "ser_customer_transaction",
  sportcash_transactions: "v1/transaction/verify-ticket/",
  transactionDetails: "ser_customer_transaction",
  payment_transactions: "getTransactions",
  updateProfile: "user/updateProfile",
  changePassword: "user/updatePassword",
  verifyPhone: "user/validate-phn",
  confirmCodeURL: "user/verifyAccountBySms",
  resendVerificationCodeBySMS: "user/resendSMSActivationCode",
  changePin: "user/updatePin",
  providers: "provider-service/",
  DizaPAY_WITHDRAWAL_CONFIRMATION: "ser_web_retrait_momo_box",
  DizaPAY_DEPOSIT_CONFIRMATION: "ser_validate_visa_transaction",
  DizaPAY_WITHDRAWAL: "ser_web_retrait_momo_box",
  DizaPAY_DEPOSIT: "ser_web_recharge_momo_box",
  LONACI_DEPOSIT: "ser_refill_local_coupon",
  saveCoupon: "coupon/save",
  loadCoupon: "coupon/",
  recover1: "user/recoverAccount",
  verifyEmail: "user/validate-email",
  verifyUserName: "user/validate-user",
  securityQuestions: "user/getQuestions",
  recover2: "user/recoverAccount/changePassword",
  SOBFLOUSVOUCHER: "ser_refill_local_coupon",
  SOBFLOUS: "ser_sobflous_recharge",
  SOBFLOUSVOUCHER_DEPOSIT: "ser_refill_local_coupon",
  ALLIANCETECH_DEPOSIT: "ser_refill_local_coupon",
  SOBFLOUS_DEPOSIT: "ser_sobflous_recharge",
  SOBFLOUS_WITHDRAWAL: "ser_sobflous_retrait",
  BET216: "ser_refill_local_coupon",
  BET221_DEPOSIT: "ser_refill_local_coupon",
  SPORTCASH_DEPOSIT: "ser_refill_local_coupon",

  VIAMOBILE: "ser_refill_local_coupon",
  BANKTRANSFER: "ser_bank_transfer",

  LATETS_WINNERS: "agent-servicev2/winners/5",
  REQUEST_OTP: "user/otp/generate",
  savePaymentAccnt: "provider-service/sendConfirmCode",
  confirmOtpPayment: "provider-service/confirmAccount",
  sessionverification: "/API/customer/session/validate",
  registerAnonym: "customer-service/register_ussd",
  placeBetMiss: "web/miss",
  calendar: "calendar",
  saveBet: "ser_hippique_bet",

  // comment for v1 

  LIVE_DATA: "sport/event/liveEvents",
  all_sports: "sport/list",
  LATEST_RESULTS: "sport/results/period/",
  SPORTCASHWITHDRAWAL_WITHDRAWAL: "ser_generate_withdrawal_code",
  BET216WITHDRAWAL: "ser_generate_withdrawal_code",
  BET221WITHDRAWAL: "ser_generate_withdrawal_code",
  BET221WITHDRAWAL_WITHDRAWAL: "ser_generate_withdrawal_code",
  SPORTCASH_WITHDRAWAL: "ser_generate_withdrawal_code",
  sports: "sport/",
  events: "sport/event",
  cashout: "cashout/offer",
  completeCashout: "cashout",
  PAYTUS_GATEWAY_DEPOSIT: "ser_web_recharge",
  PAYTUS_GATEWAY_WITHDRAWAL: "ser_web_retrait",
  refreshToken: "api/v1/authorize/refreshToken",

  

  // uncomment for v1 Services
  // LIVE_DATA: "v1/sport/event/liveEvents",
  // all_sports: "v1/sport/list",
  // LATEST_RESULTS: "v1/sport/results/period/",
  // BET216WITHDRAWAL: "v1/ser_generate_withdrawal_code",
  // BET221WITHDRAWAL: "v1/ser_generate_withdrawal_code",
  // BET221WITHDRAWAL_WITHDRAWAL: "v1/ser_generate_withdrawal_code",
  // SPORTCASH_WITHDRAWAL: "v1/ser_generate_withdrawal_code",
  // sports: "v1/sport/",
  // events: "v1/sport/event",
  // cashout: "v1/cashout/offer",
  // completeCashout: "v1/cashout",
};

export const TurfEndpoints = {
  getActiveGames: "soccer/get_active_games.jsp",
  getRaceReport: "ws_web_mobile_rapport.jsp",
};

export const expiry = 25;
export let SLIDER = {
  slides: [
    "slide_1.png",
    "slide_2.png",
    "slide_3.png",
    "slide_4.png",
    "slide_5.png",
    "slide_6.jpg",
  ],
};
export const GAME_TYPES = {
  CE: "Long Champ",
  SI: "Unitaire",
  CP: "Champ Reduit",
  CT: "Champ Total",
};

export const limits = {
  min: "400",
  max_ticket: "100000",
  max_online: "3275000",
  max_gain: "32750000",
  max_odds: "10000",
  max_event: "23",
};
export const channel = 3;
export const defaults = {pin:"1096", code: "225"}


export const GameTypes = {
  CE: "C. Elargie",
  SI: "Simple",
  CP: "Champ Partiel",
  CT: "Champ Total",
};
