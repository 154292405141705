import { MissCiService } from 'src/app/providers/miss-ci.service';
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ConfigService } from 'src/app/providers/config/config.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { WebsocketService } from 'src/app/providers/WebsocketService';
import { SkeletonServiceService } from '../skeleton-service.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  exemple = "174353000";
  header = {
    title: "MEILLEURS TURFISTES",
    subtitle: "Top 5 de la semaine",
  };
  cagnoteGames = [];
  options = {
    startVal: 6000,
    decimalPlaces: 3,
  };
  sliderItems: any;
  constructor(
    private router: Router,
    public missCiService: MissCiService,
    public configServer: ConfigService,
    public webSocket: WebsocketService,
    private userService: UserManagerService,
    public skeleton: SkeletonServiceService
  ) {}

  ngOnInit() {
    this.userService.loadSlides().then((res) => {
      this.sliderItems = res.slides;
      // this.images = tabimages;
    });
    // if (this.skeleton.theme.useGateway) {
    //   let me = this;
    //   this.webSocket.getCagnote.subscribe((data) => {
    //     if (data.length > 0) {
    //       me.cagnoteGames = data;
    //     }
    //     me.cagnoteGames = [];
    //     setTimeout(() => {
    //       if (!this.missCiService.load) {
    //         this.missCiService.load = true;
    //       }
    //     }, 100000);
    //   });
    // } else {
    //   this.missCiService.load = true;
    // }

    setTimeout(() => {
      if (!this.missCiService.load) {
        this.missCiService.load = true;
      }
    }, 100000);

    this.missCiService.load = true;

  }

  gotopage(page, tarif?) {
    const gotoUrl = this.configServer.partnerUrl + page
    window.location.href = encodeURI(gotoUrl)
  }
  gotofaq() {
    window.location.href = encodeURI(this.configServer.partnerUrl + "faq")

    // this.router.navigate([this.configServer.partnerUrl + "faq"]);
  }
}
