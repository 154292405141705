import { WebsocketService } from './../../../providers/WebsocketService';
import { ResetPassowrdComponent } from './../../reset-passowrd/reset-passowrd.component';
import { StorageService } from './../../../providers/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { MyAccountComponent } from './../../../pages/my-account/my-account.component';
import { ModalService } from './../../../providers/modal-service/ModalService';
import { SkeletonServiceService } from './../../skeleton-service.service';
import { Component, OnInit } from '@angular/core';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/providers/config/config.service';

import * as $ from 'jquery';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { ActivateAccountComponent } from '../../activate-account/activate-account.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  clock: Date;
  showPassword = false;
  username: any;
  selecetedMenu: any;
  password: any;
  languagesOffered: Array<any> = [];
  navmenus: Array<any> = [];
  selectedLanguage: any;
  partners: any;
  loginByPsuedo = true;
  window: any = window;
  tabUrl: string;

  constructor(
    private userManagementService: UserManagerService,
    public skeletonServiceService: SkeletonServiceService,
    private translate: TranslateService,
    public userManagerService: UserManagerService,
    public route: Router,
    public config: ConfigService,
    public storage: StorageService,
    public accountPanelServie: MyAccountService,
    public websocketService: WebsocketService,
    private modalService: ModalService
  ) {
    this.tabUrl = ""
    this.navmenus = [

    ];
    this.languagesOffered = [
      {
        name: 'French',
        code: 'fr',
        active: true,
        image: 'assets/images/fr_2.png',
      },
      {
        name: 'English',
        code: 'en',
        active: true,
        image: 'assets/images/en_2.png',
      },
    ];
  }

  ngOnInit() {
    setInterval(() => {
      this.skeletonServiceService.currentTime = new Date();
    }, 10000);

  }
  loadPartners() {
    // console.log('partners')
    this.userManagementService.loadActivePartners().then(res => {
      this.partners = this.userManagementService.partners;
      console.log('partners', this.partners)
    })

    // .finally(()=>{
    //   console.log("partners: ", this.partners);
    //   if (this.partners.length > 0) {
    //     this.partners.filter(item => {
    //       this.checkTabUrl(item)
    //     })
    //   }
    // });

  }

  checkTabUrl(partner){
    switch(partner.name.toLowerCase().replaceAll(' ', '-')){
      case 'miss-ci':
        this.tabUrl = 'miss-ci'; break;
        default: break;
    }

  }
  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    // console.log(this.userManagementService.userData.lang);
    this.selectedLanguage = this.languagesOffered.filter((lang) => {
      if (this.userManagementService.userData) {
        return lang.code === this.userManagementService.userData.lang;
      } else {
        return lang.code === 'fr';
      }
    })[0];
    // return lang.code === navigator.language.split('-')[0];

    this.config.$config.subscribe(res => {

      this.loadPartners();

    })

  }
  selectLanguage(lang) {
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }
  async registeruser() {
    this.config.quick_signup_enabled == 1 ? this.route.navigateByUrl('/fast-signup') : this.route.navigateByUrl('/registration');
  }
  closeModal($event) { }
  activateAccount() {
    this.modalService.destroy()

    this.modalService.init(ActivateAccountComponent, {}, {});
  }
  openPage(){
    this.route.navigateByUrl('miss')
  }
  initiateLogin() {
    this.userManagerService.forgottenpwrd = false;
    this.userManagerService.username = (!this.loginByPsuedo ? this.config.country_code : "") + this.username;
    this.userManagerService.password = this.password;
    this.userManagerService
      .login(this.loginByPsuedo)
      .then((data) => {
        if(this.userManagerService.forgottenpwrd){
          this.forgotenPassword();
          return;
        }
        // if(this.userManagementService.resetPass){
        //   this.forgotenPassword();
        //   return;
        // }
        if (data) {
          if (this.userManagementService.userData && this.userManagementService.userData.phone_number && this.userManagementService.userData.phone_number.length > 0) {
            let phone = this.userManagementService.userData.phone_number;
            if (phone.startsWith(this.config.country_code)) {
              phone = phone.split(this.config.country_code)[1]
              if (phone.length < this.config.phoneNumberLength) {
                this.storage.deleteCookie('session');
                this.config.saveUserdata(this.userManagementService.userData);
                const param = {
                  sessionToken: this.userManagementService.userData.session,
                };
                this.userManagementService.userData = null
                // this.userManagementService.logout(param);
                this.userManagementService.closeModal = false;
                this.modalService.init(MessageBoxComponent, { header: 'new_numbering', updatePhone: false, phone, msg: 'update_Phn_Num_Msg', isConfirmDialog: false }, {})
              }
            }

          }
          // this.userManagerService.showChangePasswordAlert =
          //   this.userManagerService.username ==
          //   this.userManagerService.password;
          // console.log(this.userManagerService.userData)
          if (this.userManagerService.showChangePasswordAlert || this.userManagerService.userData.isExpired) {
            // this.modalService.init(MyAccountComponent, { activeTab: '1' }, {});
          }
          if (this.userManagerService.userData && this.userManagerService.userData.session)
            this.websocketService.connect_session();
          this.username = '';
        }
      })
      .catch((err) => {
        // this.forgotenPassword();
      })
      .finally(() => {
        this.password = '';
        this.username = '';
      });
  }
  openAccounts(tabId) {
    this.modalService.destroy();
    const mobileTab = tabId == 2 ? 7 : 2;
    console.log(tabId)
    this.modalService.init(MyAccountComponent, { activeTab: tabId, mobileTab }, {});
  }
  disconnectUser() {
    const context = this;
    const param = {
      sessionToken: context.userManagerService.userData.session,
    };
    context.userManagerService
      .logout(param)
      .then((data) => { })
      .finally(() => {
        // this.websocketService.disconnectRabbitAuthenticatedUser()
        this.userManagerService.cleanLocalSession();
      });
  }
  forgotenPassword() {
    this.modalService.destroy();
    this.modalService.init(ResetPassowrdComponent, {}, {});
  }
  showTransactions() {
    this.accountPanelServie.activeAccountstab = 3;
    const mobileTab = 2;
    this.modalService.init(MyAccountComponent, { activeTab: 3, mobileTab }, {});
  }
  setdate() {
    $('#collapse2').addClass('collapsed');
    $('#collapseExample2').removeClass('show');

    $('#collapseExample').attr('aria-expanded', 'true');
    $('#collapseExample').addClass('collapse');
    $('#collapseExample').addClass('show');
    $('#collapse1').removeClass('collapsed');
  }
  decorateUrl(urlString) {
    var ga = this.window[this.window['GoogleAnalyticsObject']];
    var tracker;
    if (ga && typeof ga.getAll === 'function') {
      tracker = ga.getAll()[0]; // Uses the first tracker created on the page
      urlString = (new this.window.gaplugins.Linker(tracker)).decorate(urlString);
    }
    console.log(urlString)
    return urlString;
  }
}
