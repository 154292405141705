
import { Injectable } from '@angular/core';
import { Theme, tunisia, lonaci } from '../theme';
@Injectable({
  providedIn: 'root'
})
export class SkeletonServiceService {

  private _minHeight: any;
  private _minWidth: any;
  private _maxHeight: any;
  private _maxWidth: any;
  private _activeheaderClass: any;
  private _viewInContext: any;
  private _theme: Theme = lonaci;
  private _currentTime = new Date();
  private _activeHeader: any;
  public get activeHeader(): any {
    return this._activeHeader;
  }
  public set activeHeader(value: any) {
    this._activeHeader = value;
  }


  public get currentTime() {
    return this._currentTime;
  }
  public set currentTime(value) {
    this._currentTime = value;
  }

  public get theme(): Theme {
    return this._theme;
  }
  public set theme(value: Theme) {
    this._theme = value;
  }
  public get viewInContext(): any {
    return this._viewInContext;
  }
  public set viewInContext(value: any) {
    this._viewInContext = value;
  }

  public get activeheaderClass(): any {
    return this._activeheaderClass;
  }
  public set activeheaderClass(value: any) {
    this._activeheaderClass = value;
  }


  public get minHeight(): any {
    return this._minHeight;
  }
  public set minHeight(value: any) {
    this._minHeight = value;
  }
  public get minWidth(): any {
    return this._minWidth;
  }
  public set minWidth(value: any) {
    this._minWidth = value;
  }

  public get maxHeight(): any {
    return this._maxHeight;
  }
  public set maxHeight(value: any) {
    this._maxHeight = value;
  }

  public get maxWidth(): any {
    return this._maxWidth;
  }
  public set maxWidth(value: any) {
    this._maxWidth = value;
  }


  constructor() { }


}
