import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/wrappers/Http';
import { ConfigService } from './config/config.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpService, private configs: ConfigService) { }




  async getTokenRequest(){
    let params = { "refreshToken": this.configs.USER_AUTHORISATION ? this.configs.USER_AUTHORISATION.refresh_token : "" }
    let api = this.configs.PROXIES.userService.endsWith('v2/') ? this.configs.PROXIES.userService.split('v2/')[0] : this.configs.PROXIES.userService
    await this.http.Post(api , this.configs.EndPoints.refreshToken, params, "refreshToken").then(res => {
      if (res.erc == 1 && res.data) {
        this.configs.USER_AUTHORISATION.access_token = res.data.token
        this.configs.USER_AUTHORISATION.expiry =  new Date(res.data.expire_at)
      
      }
    }).catch(err=>{

    })
  }
}
