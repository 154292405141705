import { ResetPassowrdComponent } from './../../components/reset-passowrd/reset-passowrd.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my-account.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyTransactionsComponent } from './my-transactions/my-transactions.component';
import { AccountsComponent } from './accounts/accounts.component';
import { MyAccountService } from '../../providers/my-account/my-account.service';
import { TranslateModule } from '@ngx-translate/core';
import { MdePopoverModule } from '@material-extended/mde';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from 'src/app/components/components.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AccDetailCompteComponent } from './acc-detail-compte/acc-detail-compte.component';
import { AccParisComponent } from './acc-paris/acc-paris.component';
import { AccDepotsComponent } from './acc-depots/acc-depots.component';
import { AccRetaitsComponent } from './acc-retaits/acc-retaits.component';
import { AccRetraitGainComponent } from './acc-retrait-gain/acc-retrait-gain.component';
import { AccRechargerComponent } from './acc-recharger/acc-recharger.component';
import { AccPaiementComponent } from './acc-paiement/acc-paiement.component';
import { AccProfilComponent } from './acc-profil/acc-profil.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ActivateAccountComponent } from 'src/app/components/activate-account/activate-account.component';
import { ResetPwComponent } from './reset-pw/reset-pw.component';
import { MyBonusesComponent } from './my-bonuses/my-bonuses.component';
@NgModule({
  declarations: [MyAccountComponent, MyProfileComponent, ResetPassowrdComponent, ActivateAccountComponent,
     MyTransactionsComponent, AccountsComponent, AccDetailCompteComponent, AccParisComponent,
      AccDepotsComponent, AccRetaitsComponent, AccRetraitGainComponent, AccRechargerComponent, AccPaiementComponent, AccProfilComponent, ResetPwComponent, MyBonusesComponent],
  exports: [MyProfileComponent, MyTransactionsComponent, AccountsComponent, ActivateAccountComponent],
  entryComponents: [MyAccountComponent, ResetPassowrdComponent, ActivateAccountComponent],
  providers: [MyAccountService],
  imports: [
    FormsModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    ComponentsModule,
    MdePopoverModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    CommonModule,
    InfiniteScrollModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ]
})
export class MyAccountModule { }
