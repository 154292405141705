import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';

@Component({
  selector: 'app-acc-detail-compte',
  templateUrl: './acc-detail-compte.component.html',
  styleUrls: ['./acc-detail-compte.component.scss']
})
export class AccDetailCompteComponent implements OnInit {
  currency = this.configService.currency;
  constructor(public usermanager: UserManagerService,
              public configService: ConfigService,) { }

  ngOnInit() {
  }

}
