import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';

@Component({
  selector: 'app-acc-retrait-gain',
  templateUrl: './acc-retrait-gain.component.html',
  styleUrls: ['./acc-retrait-gain.component.scss']
})
export class AccRetraitGainComponent implements OnInit {
  procesingOTP: boolean;
  isProcessingWithdrawal: boolean = false;
  conFirmMsg: any;
  withdrawalAmt: any;
  activeMethod: any;
  accountParams: any;
  phoneNumber = '';
  msg: string;
  suffixPhone = '';
  otp: any;
  requireConfirmation: boolean;
  filteredAccnts: any;
  withdrawalMethod: any = {};
  index:any;
  yesHander;
  noHandler: any;
  constructor(public usermanagerService: UserManagerService,
    public accountService: MyAccountService,
    public configService: ConfigService,
    public translateService: TranslateService,
    public modalService: ModalService,) { 
      if(this.usermanagerService.userData)
      this.accountParams = {
        uid: this.usermanagerService.userData.customer_ID,
        lgn: this.usermanagerService.userData.login,
        pin: this.usermanagerService.userData.str_PIN ? this.usermanagerService.userData.str_PIN : '',
        chl: '3',
        wtp: 'B',
        ref: '' + new Date().getTime(),
        id: this.usermanagerService.userData.external_payment_tef,
        acc: this.usermanagerService.userData.account_code,
        lang: this.usermanagerService.userData.lang ? this.usermanagerService.userData.lang : navigator.language.split('-')[0],
        opr: ''
      };
    }

  ngOnInit() {
    let context = this;
    this.accountService.getProviders(2).then(res =>{
      // console.log(this.accountService.paymentProviders[2])
     

    })
  }
  closeConfirmMessage() {
    this.conFirmMsg = undefined;
    this.yesHander = undefined;
    this.noHandler = undefined;
  }
  showNumber(){
    // console.log(this.phoneNumber)
  }
  showConfirmDialog() {


    this.conFirmMsg = 'confirm_mobile_withdrawal';
    this.yesHander = this.triggerWithdrawal;
    this.noHandler = this.closeConfirmMessage;
  }
  sendOTP() {
    this.initMsgs();
    if (this.activeMethod.is_mobile && (isNaN(Number(this.phoneNumber)) || !this.phoneNumber)) {
      this.msg = ' Numero de telephone invalide ';
      return;
    }
    if (isNaN(Number(this.withdrawalAmt)) || !this.withdrawalAmt) {
      this.msg = 'Le montant doit être supérieur à ' + (this.activeMethod.min ? this.activeMethod.min : 0)
      return;
    }
    if (Number(this.withdrawalAmt) < Number(this.activeMethod.min)) {
      this.msg = 'Le montant minimum autorisé pour cette méthode est ' + this.activeMethod.min
      return;
    }
    if (Number(this.withdrawalAmt) > Number(this.activeMethod.max)) {
      this.msg = 'Le montant maximum autorisé pour cette méthode est ' + this.activeMethod.max
      return;
    }
    let context = this;
    context.isProcessingWithdrawal = true;
    context.accountService.getOTP(this.withdrawalAmt).then(data => {
      context.requireConfirmation = true;
      // console.log('data',data)
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      this.requireConfirmation = true;
      this.isProcessingWithdrawal = false;
    });

  }
  validateTransaction() {
    if (!this.otp || this.otp.trim() == '')
      return;

    if (this.activeMethod.code !== 'VISA UBA') {
      this.triggerWithdrawal();
      return;
    }
    this.procesingOTP = true;
    this.accountParams.sms = this.otp
    this.accountParams.pvd = this.activeMethod['pdv']
    this.accountParams.amt = this.withdrawalAmt + '';
    this.accountParams.amt = this.withdrawalAmt + '';
    this.accountParams.pdi = this.activeMethod.id;
    this.accountParams.pvd = this.activeMethod.pdv;
    this.accountParams.str_Pin = this.otp;
    this.accountParams.pin = this.otp;
    this.accountParams.phn = this.phoneNumber + this.suffixPhone;
    this.accountParams.opr = this.activeMethod.id + '';
    this.accountParams.sid = this.usermanagerService.userData.session,
    this.accountParams['pdvurl'] = this.activeMethod.code == 'LONACI' ? 'LONACI_WITHDRAWAL' : this.activeMethod['pdv'].replace(' ', '') + '_WITHDRAWAL'
    this.accountService.validateOTP(this.accountParams).then(response => {
      this.requireConfirmation =false
      this.procesingOTP = false;
      this.otp = ''
      let data = response[0]

      // console.log(data);

      if (data.erc == 1) {
        this.modalService.msg = 'withdrawal_successfully';
        // this.msg = this.configService.getErrorMessage(data[0].ERROR_MESSAGE);
        this.modalService.title = 'Success';
        this.modalService.destroy();
        this.modalService.showModal();
        this.msg = undefined;
        this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE);
        if (data.balances) {
          this.usermanagerService.updateUserAttribute('balances', data.balances);
        }
      } else {
        // this.requireConfirmation = false;
        this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE);
      }
    }).finally(() => {
      this.procesingOTP = false;
      this.otp = ''
    })
  }
  initMsgs() {
    this.msg = undefined;
  }
  triggerWithdrawal() {
    const context = this;
    this.initMsgs();
    this.requireConfirmation = false;
    this.procesingOTP = true;
    this.conFirmMsg = undefined;
    if (isNaN(Number(this.withdrawalAmt)) || !this.withdrawalAmt) {
      this.msg = 'Le montant doit être supérieur à ' + this.activeMethod.min
      return;
    }
    if (Number(this.withdrawalAmt) < Number(this.activeMethod.min)) {
      this.msg = 'Le montant minimum autorisé pour cette méthode est' + this.activeMethod.min
      return;
    }
    if (Number(this.withdrawalAmt) > Number(this.activeMethod.max)) {
      this.msg = 'Le montant maximum autorisé pour cette méthode est' + this.activeMethod.max
      return;
    }
    // console.log(this.phoneNumber)
    this.accountParams.amt = this.withdrawalAmt + '';
    this.accountParams.pdi = this.activeMethod.id;
    this.accountParams.pvd = this.activeMethod.pdv;
    this.accountParams.str_Pin = this.otp;
    this.accountParams.pin = this.otp;
    this.accountParams.phn = this.phoneNumber + this.suffixPhone;
    this.accountParams.opr = this.activeMethod.id + '';
    this.accountParams.sid = this.usermanagerService.userData.session,
    this.isProcessingWithdrawal = true;
    this.accountParams['pdvurl'] = this.activeMethod.code == 'LONACI' ? 'LONACI_WITHDRAWAL' : this.activeMethod['pdv'].replace(' ', '') + '_WITHDRAWAL'
    // this.accountParams['pdvurl'] = this.activeMethod['pdv'].replace(' ', '') + '_WITHDRAWAL';
    this.otp = undefined;
    // console.log( this.accountParams['pdvurl'], 'my provider')
    this.accountService.triggerWithdrawal(this.accountParams['pdvurl'], this.accountParams).then(data => {
      if (!this.activeMethod.is_voucher) {
        if (data.erc === '1' || (data[0] && data[0].erc) === '1') {
        this.modalService.msg = this.activeMethod.code == 'VISA UBA' ? data[0].ERROR_MESSAGE.split('.')[0] : this.configService.getErrorMessage('withdrawal_successful');
          this.modalService.title = 'Bravo !!!!!!';
          context.modalService.destroy();
          context.modalService.showModal();
          if (data.balances || (data[0] && data[0].balances)) {
            this.usermanagerService.updateUserAttribute('balances', data.balances ? data.balances : data[0].balances);
          }
        } else {
          this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE ? data.ERROR_MESSAGE : data[0].ERROR_MESSAGE);
        }

      } else {
        if (!data || !data.erc || data.erc === '0') {
          this.msg = this.configService.getErrorMessage(data.ERROR_MESSAGE ? data.ERROR_MESSAGE : data[0].ERROR_MESSAGE);
        } else {
          if (this.activeMethod.code.replace(' ', '') == 'SPORTCASHWITHDRAWAL' || this.activeMethod.code.replace(' ', '') == 'LONACI') {
            this.modalService.msg = this.translateService.instant('ml_code_sent_email');
          }
          else {
            this.modalService.msg = this.configService.getErrorMessage(data[0].ERROR_MESSAGE);
          }
          this.modalService.title = 'Bravo !!!!!!';
          this.modalService.destroy();
          this.modalService.showModal();
          if (data.balances || (data[0] && data[0].balances)) {
            this.usermanagerService.updateUserAttribute('balances', data.balances ? data.balances : data[0].balances);
          }
        }

      }

    }).catch(error => {
      // console.log(error)
      this.msg = 'verify_internet';
    }).finally(() => {
      this.isProcessingWithdrawal = false;
      this.requireConfirmation = false;
      this.procesingOTP = false;
    });
  }
  closeModal(){
    this.requireConfirmation = false;
  }
  selectMethod(i) {
    this.activeMethod = this.accountService.paymentProviders[2][i];
    // console.log('change active method', this.activeMethod)
    this.msg = undefined;
    if (this.usermanagerService.userData && this.usermanagerService.userData.providers)
      this.filteredAccnts = this.usermanagerService.userData.providers.filter(account => {
        return account.pvd == this.activeMethod.code;
      });

    this.phoneNumber = this.filteredAccnts && this.filteredAccnts.length > 0 ? this.filteredAccnts[0].accnt : undefined;
    // console.log(this.phoneNumber)
  }
}
