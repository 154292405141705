import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { ConfigService } from 'src/app/providers/config/config.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
@Component({
  selector: 'app-acc-paris',
  templateUrl: './acc-paris.component.html',
  styleUrls: ['./acc-paris.component.scss']
})
export class AccParisComponent implements OnInit {
  transDetails: any;
  isLoading: boolean;
  params: any = {};
  transactions: any[];
  dateRange: any;
  currency = this.configService.currency;
  transactionHeadings: { name: string; width: string; align: string; }[];
  providerId: any = '';
  type: any = '';
  productId: any = '';
  products: any[];
  partners: any[];
  sportcash: boolean;
  status: any;
  typeArray = [{id : 1, name: 'BET'}, { id: 2, name: 'WINNING'}]
  constructor(public userManagerService: UserManagerService,
              public configService: ConfigService, ) { }

  ngOnInit() {
    this.transactionHeadings = [
      {name: 'race', width: '20%', align: 'left'},
      {name: 'date', width: '20%', align: 'left'},
      // {name: 'id', width: '20%', align: 'left'},
      // {name: 'amount', width: '20%', align: 'right'},
      {name: 'game', width: '20%', align: 'left'},
      {name: 'combinaison', width: '20%', align: 'left'},
    ]
    this.getPartners();
    this.getProducts();
  }
  getTransactionDetails(item, target?) {
    this.sportcash = item.pvd === 'SPORTCASH' ? true : false;
    if (document.getElementById(target + item.refcode).style.display == 'none') {
      this.params = {
        uid: this.userManagerService.userData.customer_ID,
        ref: item.refcode
      }

      this.transDetails = [];
      this.isLoading = true;
      this.userManagerService.getTransactionDetails(this.params, item.pvd).then(data => {
        this.transDetails = this.sportcash ? data :  data.data;
        this.isLoading = false;
        this.open(item.refcode, target)
      }).catch(err => {
        this.transDetails = [];
        this.isLoading = false;
      }).finally(() => {

      });
    } else {
      this.open(item.refcode, target)
    }
    // console.log(item)

  }
  formatDate(date) {
    return  new Date(date).toLocaleDateString();
  }
  loadMoreEvents() {
    // console.log('hey')
    this.params.page++
    this.getHistory('load-more')
  }
  getProducts( more = '') {
    const param = {size: 50, page: 0, pid: this.providerId}
    this.userManagerService.filterProducts(param).then(response => {
      if (more === 'load-more') {
        this.products  = [...this.products, ...response.data];
        // this.productCounts = response.count;
      } else {
        this.products = response.data;
        // this.productCounts = response.count;
      }

  });
  }
  getPartners(more = '') {
    const param = {size: 50, page: 0}
    this.userManagerService.filterPartners(param).then(response => {
      if (more === 'load-more') {
        this.partners  = [...this.partners, ...response.data];
        // this.eventCounts = response.count;
      } else {
        this.partners = response.data;
        // this.eventCounts = response.count;
      }

  });
  }
  getHistory(more = '') {
    this.params.uid =  this.userManagerService.userData.customer_ID;
    this.params.startDate = new Date(this.dateRange[0]).setHours(0, 0, 0, 0);
    this.params.endDate = new Date(this.dateRange[1]).setHours(23, 59, 0, 0);
    this.params.pvd = this.providerId;
    this.params.pdt = this.productId;
    this.params.type = this.type;
    this.params.sid = this.userManagerService.userData.session;
    this.isLoading = true;
    this.userManagerService.getTransactions(this.params).then(data => {
        if (more === 'load-more') {
          // console.log('more loadded')
          this.transactions  = [...this.transactions, ...data.data];
          // this.productCounts = response.count;
        } else {
          this.transactions = data.data;
          // this.productCounts = response.count;
        }

        this.isLoading = false;
      }).catch(err => {
        this.transactions = [];
        this.isLoading = false;
      }).finally(() => {

      });
    }
    open(item, target) {
      var myDiv = document.getElementById(target + item);
      if (myDiv.style.display == 'none') {
        if (target == 'details_') {
        // console.log(target)
        $('td[id^ = "details_"]').css('display', 'none');
        myDiv.style.display = '';
        } else  if (target == 'transaction_') {
        // console.log(target)
        $('td[id^="transaction_"]').css('display', 'none');
        myDiv.style.display = '';
       } else {
       return;
             }
     } else {
       myDiv.style.display = 'none';
     }
     }
}
