import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { StorageService } from 'src/app/providers/storage.service';

@Component({
  selector: 'app-acc-profil',
  templateUrl: './acc-profil.component.html',
  styleUrls: ['./acc-profil.component.scss']
})
export class AccProfilComponent implements OnInit {
  msg = ""
  invalidMsg: string;
  invalidNumb: boolean;
  personalDetails: any = {};
  formSubmitted = true;
  userProfile: FormGroup;
  disableEmail: boolean;
  disableCNI: boolean;
  dateInvalid: boolean;
  constructor(public usermanager: UserManagerService,
    private modalService: ModalService,
    private storageService: StorageService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    console.log("loading", this.usermanager.userData)
    this.loadCountries();
    Object.keys(this.usermanager.userData ? this.usermanager.userData : {}).forEach(element => {
      this.personalDetails[element] = this.usermanager.userData[element];
      this.enabledFields(this.personalDetails);
    });
    this.loadForm();
  }
  enabledFields(personalDetails) {
    // this.disablePhone = personalDetails.phone_number && this.personalDetails.phone_number.trim() != "" ? true : false;
    this.disableEmail = personalDetails.email && this.personalDetails.email.trim() != '' ? true : false;
    this.disableCNI = personalDetails.cni && this.personalDetails.cni.trim() != '' ? true : false
    if( personalDetails.cni && this.personalDetails.cni.trim() !== '' && this.personalDetails.cni.trim().length < 8)
    this.validateCNI(personalDetails.cni)
  }
  loadForm() {
    this.userProfile = new FormGroup({
      first_name: new FormControl(this.personalDetails.first_name, [Validators.minLength(2), Validators.required]),
      last_name: new FormControl(this.personalDetails.last_name, [Validators.minLength(2), Validators.required]),
      email: new FormControl({ value: this.personalDetails.email.trim(), disabled: !this.personalDetails.isQuickSignup || (this.personalDetails.isQuickSignup && this.disableEmail) ? true : false }, [Validators.minLength(4),
      Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$'), Validators.required, ConfigService.cannotContainSpace]),
      cni: new FormControl({ value: this.personalDetails.cni.trim(), disabled: this.personalDetails.isQuickSignup && !this.disableCNI ? false : true },
      [Validators.minLength(8), Validators.required, ConfigService.cannotContainSpace]),
      // nationality: new FormControl(this.personalDetails.country, [Validators.minLength(2)]),
      birthday: new FormControl(this.personalDetails.birthday, [Validators.required]),
      // sex: new FormControl(this.personalDetails.sex, Validators.required),
      // lang: new FormControl(this.personalDetails.lang, Validators.required),
      nationality: new FormControl(this.personalDetails.nationality),
      city: new FormControl(this.personalDetails.city),
      notif_Channels: new FormControl(this.personalDetails.notif_Channels),
      address: new FormControl(this.personalDetails.address),
      // countrycode: new FormControl(this.config.countryCode, [Validators.minLength(2), Validators.required]),
      phone_number: new FormControl({ value: this.personalDetails.phone_number, disabled: true }),
      account_code: new FormControl({ value: this.personalDetails.account_code, disabled: true }),
      login: new FormControl(this.personalDetails.login.trim()),
      town: new FormControl(this.personalDetails.town),
      sid:new FormControl(this.usermanager.userData.session)
    }
    );
  }
  validateDate() {
    const validMinDate = new Date().setFullYear(new Date().getFullYear() - 18);
    if (new Date(this.userProfile.controls.birthday.value).getTime() > validMinDate)
      this.dateInvalid = true;
      else
      this.dateInvalid=false;
  }
  updateAccnt() {
    this.formSubmitted = true;
    if(this.userProfile.controls.cni.status == "DISABLED"){
      this.validateCNI(this.userProfile.controls.cni.value)
    }
    this.userProfile.get('account_code').enable();
    this.userProfile.controls.phone_number.status == "DISABLED" ? this.userProfile.get("phone_number").enable() : '';
    this.userProfile.controls.email.status == "DISABLED" ? this.userProfile.get('email').enable() : '';
    this.userProfile.controls.cni.status == "DISABLED" ? this.userProfile.get('cni').enable() : '';

    if (this.userProfile.invalid) {
      // console.log(this.userProfile.controls);
      this.userProfile.get('phone_number').disable()
      this.userProfile.get('account_code').disable()
      !this.disableEmail && this.personalDetails.isQuickSignup ? "" : this.userProfile.get('email').disable()
      !this.disableCNI && this.personalDetails.isQuickSignup ? '' : this.userProfile.get('cni').disable();
      return;
    }

    this.usermanager.isProcessing = true;
    let param = this.userProfile.value;
    this.usermanager.updateUser(param).then(data => {
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.isError = false;
        this.modalService.msg = 'Succesfully_updated_profile';
        // this.usermanager.userData = this.personalDetails;
        this.updateData(this.userProfile.value);
        this.usermanager.userData.profile_completed = data.data ? data.data.b_Profile_Completed : '';
        console.log(this.usermanager.userData);
        this.userProfile.get("phone_number").disable()
        this.userProfile.get('email').disable()
        this.userProfile.get('cni').disable()
        this.modalService.showModal();
        this.storageService.save('user', this.usermanager.userData.session);
      } else {
        this.msg = data.msg;
        this.userProfile.get('phone_number').disable()
        this.userProfile.get('account_code').disable()
        !this.disableEmail && this.personalDetails.isQuickSignup ? "" : this.userProfile.get('email').disable()
        !this.disableCNI && this.personalDetails.isQuickSignup ? '' : this.userProfile.get('cni').disable();
      }

      this.usermanager.isProcessing = false;
    }).catch(err => {
      this.modalService.destroy();
      this.modalService.isSuccess = false;
      this.modalService.isError = true;
      this.modalService.msg = 'connection_error';
      this.modalService.showModal();
    }).finally(() => {
      this.usermanager.isProcessing = false;
      this.formSubmitted=false;
    });
  }
  updateData(data) {
    Object.keys(data).forEach(element => {
      this.usermanager.userData[element] = data[element];
    });
  }
  validateCNI(cni){
    this.userProfile.controls.cni.status == "DISABLED" ? this.userProfile.get('cni').enable() : '';
    if(cni && cni.trim() != '' && cni.length < 8 || (cni as string).indexOf(" ") >=0){
     this.modalService.destroy();
    this.modalService.isSuccess = false;
    this.modalService.isError = true;
    this.modalService.msg = 'wrong_cni';
    this.modalService.showModal();
    }
    !this.disableCNI && this.personalDetails.isQuickSignup ? '': this.userProfile.get('cni').disable();
  }
  // updateAccnt() {
  //   this.usermanager.isProcessing = true;
  //   this.usermanager.updateUser().then(data => {
  //     if (data.hasOwnProperty('erc') && data.erc == '1') {
  //       this.modalService.destroy();
  //       this.modalService.isSuccess = true;
  //       this.modalService.isError = false;
  //       this.modalService.msg = data.msg;
  //       this.modalService.showModal();
  //       this.storageService.save('user', this.usermanager.userData.session)
  //     } else {
  //       this.msg = data.msg;
  //     }

  //     this.usermanager.isProcessing = false;
  //   }).catch(err => {
  //     this.modalService.destroy();
  //     this.modalService.isSuccess = false;
  //     this.modalService.isError = true;
  //     this.modalService.msg = 'connection_lost';
  //     this.modalService.showModal();
  //   }).finally(() => {
  //     this.usermanager.isProcessing = false;
  //   });
  // }
  verifyEmail(event: FocusEvent) {
    if (event.target['value']) {
      const params = { email: '' };
      params.email = event.target['value'];
      this.usermanager.verifyEmail(params).then((response) => {
        if (response.hasOwnProperty('erc') && response.erc == '1') {
          if (response.data) {
            this.userProfile.controls['email'].setErrors({
              userExist: true,
            });
          }
        }
        else {
          this.userProfile.controls['email'].setErrors({
            others: true,
          });
        }
      }).catch(err => {
        this.userProfile.controls['email'].setErrors({
          others: true,
        });
      });;
    }
    else {
      this.userProfile.controls['email'].setErrors({
        userExist: false,
      });
    }
  }

  loadCountries() {
    if (!this.usermanager.countries.length) {
      this.usermanager.getCountryList().then(resp => {
        this.usermanager.countries.forEach(country => {
          this.usermanager.dialingCodes.push(country.dialling_code);
        });
        this.usermanager.dialingCodes.sort();
      });
    }
  }
  verifyTel() {
    this.invalidMsg = '';
    let length1 = this.configService.phoneNumberLength;
    var pattern = /^[0-9]+$/
    var checkval = pattern.test(this.usermanager.userData.phone_number);
    if (!checkval) {
      this.invalidMsg = "invalid_character_in_phone_number";
    }
    let phoneLength = this.usermanager.userData.phone_number.toString().length
    if (length1 && (Number(phoneLength) !== Number(length1))) {
      checkval = false;
      this.invalidMsg = "invalid_length";
    }
    this.invalidNumb = !checkval ? true : false;

  }
}
