import { ConfigService } from './providers/config/config.service';
import { HttpService } from './wrappers/Http';
import { StorageService } from './providers/storage.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { Component } from '@angular/core';
import { ModalService } from './providers/modal-service/ModalService';
import { TranslateService } from '@ngx-translate/core';
import { WebsocketService } from './providers/WebsocketService';
import { SkeletonServiceService } from './components/skeleton-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ResetPassowrdComponent } from './components/reset-passowrd/reset-passowrd.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { MyAccountService } from './providers/my-account/my-account.service';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { MyProfileComponent } from './pages/my-account/my-profile/my-profile.component';

declare var $: any;
let context;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Sportcash';
  idleInterval: any;
  userIdleTime: any;
  session = undefined;
  id = undefined;
  page = undefined;
  url = window.location.href;
  constructor(
    public userManagementService: UserManagerService,
    private router:Router,
    public modalService: ModalService,
    private socketHandler: WebsocketService,
    private storage: StorageService,
    private configsService: ConfigService,
    public uiService: SkeletonServiceService,
    private requestHandler: HttpService,
    private route: ActivatedRoute,
    private accountService: MyAccountService,
    private translate: TranslateService) {
    this.initializeTheme();
    this.idleLogout();
    this.configsService.loadConfigs();
    //
    context = this;
  }
  openPage() {
    switch (this.page) {
      case 'my-account':
        this.modalService.init(MyAccountComponent, { activeTab: 2 }, {});
        break;
      case 'forgot-password':
        this.modalService.init(ResetPassowrdComponent, {}, {});
        break;
      case 'account-activation':
        this.modalService.init(ActivateAccountComponent, {}, {});
        break;
      case 'my-profile':
        this.modalService.init(MyProfileComponent, {activeTab: 1}, {});
        break;
    }

  }
  idleLogout() {
    let t;
    // tslint:disable-next-line: no-shadowed-variable
    const context = this;
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments
    // function yourFunction() {
    //   // console.log("Executing")
    // }

    function resetTimer() {
      context.userManagementService.lastActiveTime = new Date();
      // clearTimeout(t);
      // t = setTimeout(yourFunction, 10000);  // time is in milliseconds
    }
  }

ngOnInit(){
  this.configsService.$config.subscribe(res => {
    this.userManagementService.loadSlides().then(res => {
      this.userManagementService.images = res.slides
    })
    this.getProviders();
    var dataContext = this.url.split('?')[1]
    dataContext = dataContext && dataContext.includes("#/&") ? dataContext.split('#/&')[1] : dataContext;

    if (dataContext) {
      const dataArray = dataContext.split('&')
      const params = {}
      dataArray.forEach(data => {
        let keyValue = data.split('=');
        params[keyValue[0]] = keyValue[1]
      })
      // this.userManagementService.setTokenData(params)
      this.page = params['page']

      this.id = { session: params['session'] };
      this.session =params['session'];
      // this.userManagementService.redirect = params['redirect'];
      this.userManagementService.redirect = decodeURIComponent(params['redirect']);
    }

    if (!this.session && this.storage.checkCookie('session')) {
      this.session = this.storage.getCookie('session');
    }
    this.requestHandler.get_request(this.configsService.PROXIES.userService ,'system/configs', {}).then(response => {
      if (response.hasOwnProperty('erc') && response.erc == "1") {
        // console.log(response.data)
        this.configsService.country_code = response.data ? this.getValue(response.data, 'DEFAULT_COUNTRY_CODE') : undefined;
        // this.configsService.site_name = response.data ? this.getValue(response.data, 'STR_SITE_NAME') : undefined;
        this.configsService.phoneNumberLength = response.data && this.getValue(response.data, 'PHONE_NUMBER_LENGTH') ? this.getValue(response.data, 'PHONE_NUMBER_LENGTH') : '10';
        this.configsService.login_required = response.data && this.getValue(response.data, 'B_LOGIN_REQUIRED') ? this.getValue(response.data, 'B_LOGIN_REQUIRED') : 0;
        this.configsService.quick_signup_enabled = response.data && this.getValue(response.data, 'B_ENABLE_QUICK_SIGNUP') ? this.getValue(response.data, 'B_ENABLE_QUICK_SIGNUP') : 0;
        this.configsService.usePromoCode = response.data && this.getValue(response.data, 'USE_PROMO_CODE_REGISTRATION') ? this.getValue(response.data, 'USE_PROMO_CODE_REGISTRATION') : 0;
        this.configsService.useRefAgentCode = response.data && this.getValue(response.data, 'USE_REFERAL_CODE_REGISTRATION') ? this.getValue(response.data, 'USE_REFERAL_CODE_REGISTRATION') : 0;
        this.configsService.BL_ALLOW_LOGIN_PHONE_NUMBER = response.data && this.getValue(response.data, 'BL_ALLOW_LOGIN_PHONE_NUMBER') ? this.getValue(response.data, 'BL_ALLOW_LOGIN_PHONE_NUMBER') : 0;
        this.configsService.STR_MISS_CI_PIN = response.data && this.getValue(response.data, 'STR_MISS_CI_PIN') ? this.getValue(response.data, 'STR_MISS_CI_PIN') : 1357;

      }
      else {
        this.configsService.phoneNumberLength = 10
      }

    }).finally(() => {
      // if (this.session) {
        this.authenticateUser().then(data => {
          if (this.userManagementService.redirect)
            this.openPage();
        })
        // .finally(()=>{
        //   this.clearUrl()
        // });

      // }
    });
  });
}
clearUrl(){
  if (this.url.includes('?')) {
    this.id = { session: this.url.split('=')[1] };
    // this.id = {session: this.id};
    // window.location.href = this.url.split('session')[0];
    this.router.navigate([], {
      queryParams: {
        session: null,
      },
      queryParamsHandling: 'merge'
    });}
}

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {

    const lang = 'fr';
    this.translate.use(lang);

    this.userManagementService.resetPass.subscribe((res)=>{
      if(res === 1){
        this.forgotenPassword();
          return;
      }
    })

  }
  getProviders() {
    this.userManagementService.validProviders = [];
    this.accountService.getProviders(1).then(data => {
      for (let provider of this.accountService.paymentProviders[1]) {
        if (provider.hasOwnProperty('mask') && provider.mask.trim() != "") {
          let mask = provider.mask.split('#')[0];
          if (mask && mask.split('-').length > 0) {
            for (let item of mask.split('-')) {
              this.userManagementService.validProviders.push(item)
            }
          }
          else if (mask && mask.split('-').length == 1) {
            this.userManagementService.validProviders.push(mask)
          }

        }
      }

    });
  }
  forgotenPassword() {
    this.userManagementService.passModal = true;
    this.modalService.destroy();
    this.modalService.init(ResetPassowrdComponent, {}, {});   
    
  }

  authenticateUser() {

    if (this.session){
      // if (!this.configsService.USER_AUTHORISATION || !this.configsService.USER_AUTHORISATION.access_token) {
      //   this.configsService.USER_AUTHORISATION = this.storage.getObject('auth', {})
      // }
      return this.userManagementService.authSession(this.session).then(res => {
        // console.log(this.configsService.phoneNumberLength)
        if (this.userManagementService.userData && this.userManagementService.userData.phone_number && this.userManagementService.userData.phone_number.length > 0) {
          let phone = this.userManagementService.userData.phone_number;
          if (phone.startsWith(this.configsService.country_code)) {
            phone = phone.split(this.configsService.country_code)[1]
            if (phone.length < this.configsService.phoneNumberLength) {
              // console.log(phone.length)
              // this.storage.deleteCookie('session');
              this.configsService.saveUserdata(this.userManagementService.userData);
              const param = {
                sessionToken: this.userManagementService.userData.session,
              };
              this.userManagementService.userData = null
              // this.userManagementService.logout(param);
              this.userManagementService.closeModal = false;
              this.modalService.init(MessageBoxComponent, { header: 'Nouvelle numérotation.', updatePhone: false, phone, msg: 'update_Phn_Num_Msg', isConfirmDialog: false }, {})
            } else if (this.userManagementService.userData && this.page == 'my-account') {
              const mobileTab = 7;

              this.modalService.init(MyAccountComponent, { activeTab: 2, mobileTab }, {});
            }
          }

        }

        // this.socketHandler.connect_session();
      });
    }

    else{
      if (this.userManagementService.redirect)
            this.openPage();
      return Promise.reject("rere");
    }
  }



  getValue(configs: Array<any>, arg1: string): any {
    let valueToReturn;
    configs.forEach(config => {
      if (config.key === arg1) {
        valueToReturn = config.value;
      }
    });
    return valueToReturn;
  }
  removeModal() {
    // if (this.userManagementService.showChangePasswordAlert) {
    //   return;
    // }
    this.modalService.destroy();
    this.userManagementService.passModal = false;
  }
  initializeTheme() {
    for (const property of Object.keys(this.uiService.theme.properties)) {
      document.documentElement.style.setProperty(property, this.uiService.theme.properties[property]);
    }
  }

}
