import { Component, Input, OnInit } from '@angular/core';
import { MyAccountComponent } from 'src/app/pages/my-account/my-account.component';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { StorageService } from 'src/app/providers/storage.service';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit {
 @Input() header:any;
 @Input() msg:any;
 @Input() isConfirmDialog:any;
 @Input() phone: any;
  showConfirmModal: boolean;
  isProcessing =false
  phoneNumberTypes = [
    {tp: 'mobile', value : ''},
    {tp: 'fixed', value : ''},

  ]
  pvd: any;
  phn = this.phoneNumberTypes[0];
  constructor( private modalService: ModalService, public accountService: MyAccountService,
    private storageService: StorageService,
    private storage: StorageService,
    public config: ConfigService,
    public userService: UserManagerService) { }

  ngOnInit() {
    this.showConfirmModal = false;
    // this.accountService.getProviders(1).then(res =>{})
  }
  onYesClick(){
    this.showConfirmModal = true;
    // this.modalService.destroy();
    // this.modalService.init(MyAccountComponent, { activeTab: '1' }, {});
  }
  saveChanges(){
    console.log(this.pvd)
    this.isProcessing = true;
if(this.pvd)
{
  let mask = this.pvd.split('#')[0];
  if(mask && mask.split('-').length>1){
    if(this.phn.tp == 'fixed'){
      this.config.tempData.phone_number = mask.split('-')[0] + this.phone
    }
    else{
      this.config.tempData.phone_number = mask.split('-')[1] + this.phone
    }
  }
else if(mask && mask.split('-').length == 1){
  this.config.tempData.phone_number = mask.split('-')[0] + this.phone
}
this.config.tempData.phone_number = this.config.country_code.concat(this.config.tempData.phone_number)
  this.userService.updateUser(this.config.tempData).then(data =>{
    if (data.hasOwnProperty('erc') && data.erc == '1') {
      this.modalService.destroy();
      this.modalService.isSuccess = true;
      this.modalService.isError = false;
      this.modalService.msg = data.msg;
      this.modalService.showModal();
      this.userService.userData = {}
      // this.userService.authSession({session: this.config.tempData.session})
      this.storage.setCookie('session', this.config.tempData.session, this.config.expiry);
      Object.assign(this.userService.userData, this.config.tempData) 
      this.userService.closeModal= true;

      this.storageService.save('user', this.config.tempData.session)
    } else {
      this.msg = data.msg;
    }

    // this.userService.isProcessing = false;
  }).catch(err => {
    this.modalService.destroy();
    this.modalService.isSuccess = false;
    this.modalService.isError = true;
    this.modalService.msg = 'connection_error';
    this.modalService.showModal();
  }).finally(() => {
    this.isProcessing = false;
  });
}
  }
}
