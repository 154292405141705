import { ModalService } from './../../../providers/modal-service/ModalService';
import { UserManagerService } from '../../../providers/services/userManager/user-manager.service';
import { Component, OnInit, Input } from '@angular/core';
import { StorageService } from 'src/app/providers/storage.service';
import { MyAccountService } from 'src/app/providers/my-account/my-account.service';
import { ConfigService } from 'src/app/providers/config/config.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  @Input() activeTab: any ;
  msg: any;
  accounts = [

  ]
  procesingOTP = false;
  type: any;
  mode: any;
  otp: any;
  currentVerificationID;
  otpRequired = false;
  shownMsg: boolean;
  password: any = {};
  mobileProviders: any;
  headers: Array<any> = [
    {
      name: 'personal_data',
      id: 1
    },
    {
      name: 'change_password',
      id: 2
    },
    {
      name: 'my_accounts',
      id: 3
    }
  ];
  activeHeader: any;
  pins: any = {};
  accnt: any;
  pvd: any;
  showAddMethodForm = false;
  invalidMessge: string;
  invalidNumber: boolean;
  invalidNumb: boolean;
  invalidMsg: string;
  invalid: boolean;
  formSubmitted = true;
  userProfile: FormGroup;
  personalDetails: any = {};
  disableEmail: any;
  disableCNI: any;
  constructor(public usermanagerService: UserManagerService,
    private storageService: StorageService,
    public providerService: MyAccountService,
    public configService: ConfigService,
    private modalService: ModalService) {

    this.activeHeader = this.usermanagerService.showChangePasswordAlert || this.usermanagerService.userData&&this.usermanagerService.userData.isExpired ? this.headers[1] : this.headers[0];
        Object.keys(this.usermanagerService.userData?this.usermanagerService.userData:{}).forEach(element => {
          this.personalDetails[element] = this.usermanagerService.userData? this.usermanagerService.userData[element]:'';
          this.enabledFields(this.personalDetails);
        });

    if(this.activeTab){
      this.activeHeader = this.headers[this.activeTab]
    }
  }
  enabledFields(personalDetails) {
    // this.disablePhone = personalDetails.phone_number && this.personalDetails.phone_number.trim() != "" ? true : false;
    this.disableEmail = personalDetails.email && this.personalDetails.email.trim() != '' ? true : false;
    this.disableCNI = personalDetails.cni && this.personalDetails.cni.trim() != '' ? true : false
    if( personalDetails.cni && this.personalDetails.cni.trim() !== '' && this.personalDetails.cni.trim().length < 8)
    this.validateCNI(personalDetails.cni)
  }
  addPaymentAccnt() {
    this.showAddMethodForm = true;
  }

  switcHeader(header) {
    let context = this;
    this.msg = undefined;
    this.activeHeader = header;
    if (this.activeHeader.id == 3) {
      this.providerService.getProviders(0).then(data => {
        // console.log(this.providerService.paymentProviders)
      })
    }
  }
  ngOnInit() {
    if (!this.usermanagerService.countries.length) {
      this.usermanagerService.getCountryList().then(resp => {
        this.usermanagerService.countries.forEach(country => {
          this.usermanagerService.dialingCodes.push(country.dialling_code);
        });
        this.usermanagerService.dialingCodes.sort();
      });
    }

    this.userProfile = new FormGroup({
      first_name: new FormControl(this.personalDetails.first_name, [Validators.minLength(2), Validators.required]),
      last_name: new FormControl(this.personalDetails.last_name, [Validators.minLength(2), Validators.required]),
      email: new FormControl({ value: this.personalDetails.email.trim(), disabled: !this.personalDetails.isQuickSignup || (this.personalDetails.isQuickSignup && this.disableEmail) ? true : false }, [Validators.minLength(4),
      Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$'), Validators.required, ConfigService.cannotContainSpace]),
      cni: new FormControl({ value: this.personalDetails.cni.trim(), disabled: this.personalDetails.isQuickSignup &&
         !this.disableCNI ? false : true }, [Validators.minLength(8), Validators.required, ConfigService.cannotContainSpace]),
      // nationality: new FormControl(this.personalDetails.country, [Validators.minLength(2)]),
      birthday: new FormControl(this.personalDetails.birthday, [Validators.required]),
      sex: new FormControl(this.personalDetails.sex, Validators.required),
      // lang: new FormControl(this.personalDetails.lang, Validators.required),
      nationality: new FormControl(this.personalDetails.nationality),
      city: new FormControl(this.personalDetails.city),
      notif_Channels: new FormControl(this.personalDetails.notif_Channels),
      address: new FormControl(this.personalDetails.address),
      // countrycode: new FormControl(this.config.countryCode, [Validators.minLength(2), Validators.required]),
      phone_number: new FormControl({ value: this.personalDetails.phone_number, disabled: true }),
      account_code: new FormControl({ value: this.personalDetails.account_code, disabled: true }),
      login: new FormControl(this.personalDetails.login.trim()),
      town: new FormControl(this.personalDetails.town),
      sid:new FormControl(this.usermanagerService.userData.session)

    }
    );
  }
  initMsgs() {
    this.msg = undefined;
  }
  ngAfterViewInit() {
    let context = this;
    if(context.usermanagerService.userData)
    $('#birthDate').datepicker({
      // dateFormat: 'dd/mm/yy',

      value: context.usermanagerService.userData.dt_AGENT_BIRTHDAY,
      onSelect: (newDate) => {
        context.usermanagerService.userData.dt_AGENT_BIRTHDAY = newDate;
      }
    });
  }
  validateCNI(cni){
    this.userProfile.controls.cni.status == "DISABLED" ? this.userProfile.get('cni').enable() : '';
    if(cni && cni.trim() != '' && cni.length < 8 || (cni as string).indexOf(" ") >=0){
     this.modalService.destroy();
    this.modalService.isSuccess = false;
    this.modalService.isError = true;
    this.modalService.msg = 'wrong_cni';
    this.modalService.showModal();
    }
    !this.disableCNI && this.personalDetails.isQuickSignup ? '': this.userProfile.get('cni').disable();
  }
  validateOTP() {
    this.procesingOTP = true;
    const requestData = {
      sid: this.usermanagerService.userData.session,
      otp: this.otp,
      id: this.pvd.split('-')[1],
      pvd: this.pvd.split('-')[0]
    };
    this.usermanagerService.confirmpaymentAccount(requestData).then(response => {
      this.msg = "operation_successful"
      this.otpRequired = false
      this.usermanagerService.userData.providers = this.providerService.setProvPhoneNumbers(response.data);
      this.showAddMethodForm = false;
    }).catch(e=>{
      this.msg = e.msg;
    })
    .finally(() => {
      this.procesingOTP = false;
      this.showAddMethodForm = false;
      this.otp = '';
      this.otpRequired = false
    })
  }
  changeNotifPref(pref) {
    if (!this.usermanagerService.userData.notif_Channels)
      this.usermanagerService.userData.notif_Channels = []
    if (this.usermanagerService.userData.notif_Channels && this.usermanagerService.userData.notif_Channels.indexOf(pref) >= 0) {
      this.usermanagerService.userData.notif_Channels.splice(this.usermanagerService.userData.notif_Channels.indexOf(pref), 1)
    } else {
      this.usermanagerService.userData.notif_Channels.push(pref)
    }
  }
  updateAccnt() {
    this.formSubmitted = true;
    if(this.userProfile.controls.cni.status == "DISABLED"){
      this.validateCNI(this.userProfile.controls.cni.value)
    }
    this.userProfile.get('account_code').enable();
    this.userProfile.controls.phone_number.status == "DISABLED" ? this.userProfile.get("phone_number").enable() : '';
    this.userProfile.controls.email.status == "DISABLED" ? this.userProfile.get('email').enable() : '';
    this.userProfile.controls.cni.status == "DISABLED" ? this.userProfile.get('cni').enable() : '';

    if (this.userProfile.invalid) {
      console.log(this.userProfile.controls);
      this.userProfile.get('phone_number').disable()
        this.userProfile.get('account_code').disable()
        !this.disableEmail && this.personalDetails.isQuickSignup ? "": this.userProfile.get('email').disable()
        !this.disableCNI && this.personalDetails.isQuickSignup ? '': this.userProfile.get('cni').disable();
      return;
    }
    let param = this.userProfile.value;

    this.usermanagerService.isProcessing = true;
    this.usermanagerService.updateUser(param).then(data => {
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.isError = false;
        this.modalService.msg = 'Succesfully_updated_profile';
        // this.usermanagerService.userData = this.personalDetails;
        this.updateData(this.userProfile.value);
        this.usermanagerService.userData.profile_completed = data.data ? data.data.b_Profile_Completed : '';
        console.log(this.usermanagerService.userData);
        this.userProfile.get("phone_number").disable()
        this.userProfile.get('email').disable()
        this.userProfile.get('cni').disable()
        this.modalService.showModal();
        this.storageService.save('user',this.usermanagerService.userData.session);
      } else {
        this.msg = data.msg;
        this.userProfile.get('phone_number').disable()
        this.userProfile.get('account_code').disable()
        !this.disableEmail && this.personalDetails.isQuickSignup ? "": this.userProfile.get('email').disable()
        !this.disableCNI && this.personalDetails.isQuickSignup ? '': this.userProfile.get('cni').disable();
      }

      this.usermanagerService.isProcessing = false;
    }).catch(err => {
      this.modalService.destroy();
      this.modalService.isSuccess = false;
      this.modalService.isError = true;
      this.modalService.msg = 'connection_error';
      this.modalService.showModal();
    }).finally(() => {
      this.usermanagerService.isProcessing = false;
    });
  }
  updateData(data){
    Object.keys(data).forEach(element => {
       this.usermanagerService.userData[element] = data[element];
      // if (this.personalDetails.birthday) {
      //   this.personalDetails.birthday = this.helper.initDate(this.personalDetails.birthday);
      // }
    });
  }
  changePasswords() {
    if (this.password.str_Password_old == this.password.str_Password) {
      this.msg = "old_pass_equal_new_passs";
      return;
    }
    if (this.password.str_Password == this.usermanagerService.userData.login) {
      this.msg = "username_can_not_be_same_as_password";
      return;
    }
    if (this.password.str_Password != this.password.str_Password2) {
      this.msg = "password_confirmation_incorrect";
      return;
    }
    this.password.str_Login = this.usermanagerService.userData.login;
    this.usermanagerService.isProcessing = true;
    this.usermanagerService.changePassword(this.password).then(data => {
      this.usermanagerService.isProcessing = false;
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.usermanagerService.showChangePasswordAlert = false;
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.isError = false;
        this.modalService.msg = "password_updated";
        this.modalService.showModal();
        this.usermanagerService.userData.isExpired = false;
      } else {
        this.msg = data.msg;
      }


    }).catch(data => {
      this.modalService.destroy();
      this.modalService.isSuccess = false;
      this.modalService.isError = true;
      this.modalService.msg = 'connection_error';
      this.modalService.showModal();
    })
      .finally(() => {
        this.usermanagerService.isProcessing = false;
      });
  }
  verifyEmail(event: FocusEvent) {
    if(event.target['value']){
    const params = { email: '' };
    params.email = event.target['value'];
    this.usermanagerService.verifyEmail(params).then((response) => {
      if (response.hasOwnProperty('erc') && response.erc == '1') {
        if (response.data) {
          this.userProfile.controls['email'].setErrors({
            userExist: true,
          });
        }
      }
      else {
        this.userProfile.controls['email'].setErrors({
          others: true,
        });
      }
    }).catch(err=>{
      this.userProfile.controls['email'].setErrors({
        others: true,
      });
    });
  }
  else{
    this.userProfile.controls['email'].setErrors({
      userExist: false,
    });
  }
  }

  sendNumberOTP() {
    this.verifyPhoneNumber();
    if (this.invalidNumber) {
      this.msg = "invalid_phonenumber";
      return;
    }
    this.usermanagerService.isProcessing = true;
    const request = {
      sid: this.usermanagerService.userData.session,
      pvd: this.pvd.split('-')[1],
      // accnt: this.configService.country_code + this.accnt
      // saving payment accounts without country code
      accnt: this.accnt
    }
    this.usermanagerService.saveAccount(request).then(data => {
      this.otpRequired = true;
    }).catch(err => {
      this.msg = err.msg ? err.msg : err.message
      //  console.log(this.msg)
    })
      .finally(() => {
        this.usermanagerService.isProcessing = false;
      })
    // console.log("adding new number")
  }
  changePin() {
    this.usermanagerService.isProcessing = true;
    if (this.pins.str_Pin == this.pins.str_Pin2) {
      this.pins.str_Login = this.usermanagerService.userData.str_Login;
      this.usermanagerService.changePin(this.pins).then(data => {
        this.usermanagerService.isProcessing = false;
        if (data.hasOwnProperty('erc') && data.erc == '1') {
          this.modalService.destroy();
          this.modalService.isSuccess = true;
          this.modalService.msg = data.msg;
          this.modalService.showModal();
        }
        else {
          this.msg = data.msg;
        }
      }).catch(data => {
        this.modalService.destroy();
        this.modalService.isSuccess = false;
        this.modalService.isError = false;
        this.modalService.msg = 'connection_error';
        this.modalService.showModal();
      })
        .finally(() => {
          this.usermanagerService.isProcessing = false;
        });

    } else {
      this.msg = 'pin_not_matched';
    }

  }
  closeModal() {
    this.otpRequired = false;
  }
  verifyPhoneNumber() {
    // console.log(this.activeMethod.mask);
    let mask = this.pvd.split('-')[2];
    console.log(this.pvd);
    let length1 = mask && mask.indexOf('#') > -1 ? mask.split('#')[1] : this.configService.phoneNumberLength;
    var pattern = /^[0-9]+$/
    // // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/
    var checkval = pattern.test(this.accnt);
    if (!checkval) {
      this.invalidMessge = "invalid_character_in_phone_number";
    }
    let phoneLength = this.accnt.toString().length
    if (length1 && (Number(phoneLength) !== Number(length1))) {
      checkval = false;
      this.invalidMessge = "invalid_length";
    }
    if (!checkval) {
      this.invalidNumber = true;

    } else {
      let testMasks = mask && mask.indexOf('#') ? mask.split('#')[0].split(',') : [];
      if (testMasks && testMasks.length > 0) {
        console.log(testMasks)
        for (let maskitem of testMasks) {
          if (this.accnt.toString().indexOf(maskitem) == 0) {
            this.invalidMessge = "";
            return this.invalidNumber = false;
          }
          else {
            this.invalidNumber = true
            this.invalidMessge = "invalid_prov_code";
          }

        }
      } else {
        this.invalidMessge = "";
        this.invalidNumber = false
      }
    }
  }
  verifyPassword() {
    var pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)\S{6,}$/
    // var pattern2 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/

    var checkval = pattern.test(this.password.str_Password);
    // console.log(checkval)
    if (!checkval) {
      this.invalid = true;
    } else {
      this.invalid = false;
    }
  }
  verifyTel() {
    this.invalidMsg = '';
    let length1 = this.configService.phoneNumberLength;
    var pattern = /^[0-9]+$/
    var checkval = pattern.test(this.usermanagerService.userData.phone_number);
    if (!checkval) {
      this.invalidMsg = "invalid_character_in_phone_number";
    }
    let phoneLength = this.usermanagerService.userData.phone_number.toString().length
    if (length1 && (Number(phoneLength) !== Number(length1))) {
      checkval = false;
      this.invalidMsg = "invalid_length";
    }
    this.invalidNumb = !checkval ? true : false;

  }
}
