import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';

@Component({
  selector: 'app-acc-retaits',
  templateUrl: './acc-retaits.component.html',
  styleUrls: ['./acc-retaits.component.scss']
})
export class AccRetaitsComponent implements OnInit {

  currency = this.configService.currency;
  depotTransaction: any[];
  isLoading: boolean;
  fromDate: any;
  toDate: any;
  status: any;
  dateRange: any
  constructor(public usermanager: UserManagerService,
              public configService: ConfigService,) { }

  ngOnInit() {
  }
  getHistory() {
  //  console.log(this.dateRange)
    let params = {
        uuid: this.usermanager.userData.customer_ID,
        day: this.dateRange[0].getFullYear() + "-" + (this.dateRange[0].getMonth() + 1) + "-" + this.dateRange[0].getDate(),
        day2: this.dateRange[1].getFullYear() + "-" + (this.dateRange[1].getMonth() + 1) + "-" + this.dateRange[1].getDate(),
        tp: 'R',
      };
   
    this.depotTransaction = [];
    this.isLoading = true;
    this.usermanager.getAccountTransactions(params).then(data => {
      this.depotTransaction = data;
      this.isLoading = false;
    }).catch(err => {
      this.depotTransaction = [];
      this.isLoading = false;
    }).finally(() => {

    });
  }
}
