import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/providers/config/config.service';
import { ModalService } from 'src/app/providers/modal-service/ModalService';
import { UserManagerService } from 'src/app/providers/services/userManager/user-manager.service';
import { StorageService } from 'src/app/providers/storage.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

  email: any;
  phone: any;
  login: any;
  option: any = "EMAIL"
  user: any;
  verificationData: any = {};
  verificationCode= '';
  resendingCode = false;
  show = false;
  constructor(public userManagerService: UserManagerService, private storageService: StorageService,
    public modalService: ModalService, private translate: TranslateService, public config : ConfigService) { }

  ngOnInit() {
    // this.user =  true;
  }
  selectOption(option) {
    // console.log(option)
    this.option = option;
  }
  resendActivation() {
    if(this.option == 'SMS'){
      this.resendCode()
      return;
    }
    const params = {
      email: this.email,
    };
    this.userManagerService.isProcessing = true;
    this.userManagerService.resendActivation(this.email).then(response => {
      if (response.hasOwnProperty('erc') && response.erc == '1') {
        this.modalService.isError = false;
        this.modalService.isSuccess = true;
        this.modalService.destroy();
        this.modalService.msg = this.translate.instant("account_created_title", {email:this.email});
        this.modalService.showModal();
      }
      else if (response.hasOwnProperty('msg') && response.msg !== '') {
        // this.modalService.isError = true;
        // this.modalService.isSuccess = false;
        // this.modalService.destroy();
        
        this.modalService.msg = response.msg 
        // this.modalService.showModal();
      }
      else {
        throw "ml_message_server_error";
      }

      this.userManagerService.isProcessing = false;
    }).catch(data => {
      this.modalService.destroy();
      this.userManagerService.isProcessing = false;
      this.modalService.isError = true;
      this.modalService.isError = true;
      this.modalService.isSuccess = false;
      this.modalService.msg = data ? data :  'connection_lost';
      this.modalService.showModal();

    });
  }
  resendCode() {
    let phone = this.config.country_code + '' + this.phone;
    if (this.phone.length < 7) {
      phone = this.userManagerService.userphone;
    }
    var params = { phone, lang: navigator.language };
    this.userManagerService.isProcessing = true;
    this.userManagerService.resendSMS(params).then(data => {
      if (data.erc && data.erc == "1") {
        // this.startWaitTimer();
        this.userManagerService.userID = data.data && data.data.lg_AGENT_ID ? data.data.lg_AGENT_ID.toString() : ''
        this.user = true;
        this.modalService.msg = '';
      } else {
        this.modalService.msg = data.msg;
      }
    }).finally(() => {
      this.verificationCode = '';
      this.userManagerService.isProcessing = false;
    });
  }
  confirmCode() {
    if (this.verificationCode === '') {
      return;
    }
    const params = {
      agentId: this.userManagerService.userID,
      lang: navigator.language,
      activationCode: this.verificationCode,
      channel: '3',
      channel_id: 3
    };
    this.userManagerService.isProcessing = true;
    this.userManagerService.confirmCodeRequest(params).then(response => {
      
      this.modalService.msg = '';
      // this.userManagerService.userData = response;
      // this.userManagerService.buildUserData(response);
      this.verificationCode = '';
        this.modalService.isError = false;
        this.modalService.isSuccess = true;
        this.modalService.destroy();
        this.modalService.msg = this.translate.instant('account_activate_with_success');
        this.modalService.showModal();
      // this.storageService.save('user', this.userManagerService.userData.session);
      // this.storageService.save('user', this.userData);
      this.userManagerService.verifyUser = false;
      // }
      this.userManagerService.onLogIn.next(1);
    }).catch(msg => {
      this.modalService.msg = this.translate.instant(msg);
    }).finally(() => this.userManagerService.isProcessing = false);
  }
  isEmailValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  closeView() {
    this.modalService.destroy();
  }
  validate() {

    const params = this.verificationData;
    params.agent_id = this.user.lg_AGENT_ID;
    this.userManagerService.isProcessing = true;
    this.userManagerService.resetPassword(params).then(data => {
      this.userManagerService.isProcessing = false;
      if (data.hasOwnProperty('erc') && data.erc == '1') {
        this.modalService.destroy();
        this.modalService.isSuccess = true;
        this.modalService.isError = false;
        this.modalService.msg = data.msg;
        this.modalService.showModal();
      } else {
        // this.modalService.destroy();
        this.modalService.isError = true;
        this.modalService.msg = data.msg;
        // this.modalService.showModal();
      }
    }).catch(err => {
      this.modalService.destroy();
      this.modalService.isError = true;
      this.modalService.msg = 'connection_lost';
      this.modalService.showModal();
    }).finally(() => {

      this.userManagerService.isProcessing = false;
    });
  }
}
